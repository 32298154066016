// new
// auth
export const IS_AUTH = "IS_AUTH";
export const SET_AUTH = "SET_AUTH";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const GET_CODE = "GET_CODE";
export const RESET_PASSWORD = "RESET_PASSWORD";

// new
// message
export const SET_MESSAGE = "SET_MESSAGE";

// new
// category
export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const UPDATE_CATEGORY_ORDER = "UPDATE_CATEGORY_ORDER";

// new
// location
export const GET_LOCATIONS = "GET_LOCATIONS";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const ADD_LOCATION = "ADD_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";

// new
// post
export const GET_POSTS = "GET_POSTS";
export const SET_POSTS = "SET_POSTS";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const UPDATE_POST = "UPDATE_POST";

// post order (it will go to post actions)
export const REORDER_POSTS = "REORDER_POSTS";

// new
// writing category
export const GET_WRITING_CATEGORIES = "GET_WRITING_CATEGORIES";
export const SET_WRITING_CATEGORIES = "SET_WRITING_CATEGORIES";
export const ADD_WRITING_CATEGORY = "ADD_WRITING_CATEGORY";
export const UPDATE_WRITING_CATEGORY = "UPDATE_WRITING_CATEGORY";
export const DELETE_WRITING_CATEGORY = "DELETE_WRITING_CATEGORY";
export const UPDATE_WRITING_CATEGORY_ORDER = "UPDATE_WRITING_CATEGORY_ORDER";

// new
// writing
export const ADD_WRITING = "ADD_WRITING";
export const GET_WRITINGS = "GET_WRITINGS";
export const SET_WRITINGS = "SET_WRITINGS";
export const DELETE_WRITING = "DELETE_WRITING";
export const UPDATE_WRITING = "UPDATE_WRITING";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const CHECK_WRITING_FILES = "CHECK_WRITING_FILES";
