import React from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FileDrop } from "react-file-drop";
import {
  Button,
  FormGroup,
  // Label,
  Input,
  // Container,
  Form,
  Label,
  Col,
  Modal,
  Spinner,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as actions from "../../../store/actions/index";
import { BASE_URL } from "../../../consts/const";

class UpdateWriting extends React.Component {
  state = {
    title: "",
    body: "",
    is_modal_open: false,
    deleted_images: [],
  };

  componentDidMount() {
    const { get_writing_categories } = this.props;
    get_writing_categories();

    const { writing } = this.props.location;
    console.log("writing", writing, this.props.location);
    if (!writing) {
      const { push } = this.props.history;
      push({
        pathname: "/admin/published",
      });
      return;
    }
    const { title, body, publish_at, image_urls, category_id } = writing;
    this.setState({
      title,
      body,
      category_id,
      publish_at: new Date(publish_at),
    });

    console.log(image_urls);
    if (image_urls) {
      const { update_admin_uploaded_images_array } = this.props;
      const upload_images_array = image_urls.split(",");
      update_admin_uploaded_images_array(upload_images_array);
    }
  }

  componentWillUnmount() {
    const { init_admin_upload_images_finished_array } = this.props;
    init_admin_upload_images_finished_array();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.admin_update_writing_status !==
      this.props.admin_update_writing_status
    ) {
      const { admin_update_writing_status } = nextProps;
      if (admin_update_writing_status === "pending") {
        this.setState({
          modal_componet: (
            <div>
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
              <Spinner type="grow" color="warning" />
              <Spinner type="grow" color="info" />
              <Spinner type="grow" color="light" />
              <Spinner type="grow" color="dark" />
            </div>
          ),
        });
      }
      if (admin_update_writing_status === "done") {
        this.setState({
          modal_componet: <div>Updated successfully</div>,
        });
      }
      if (admin_update_writing_status === "failure") {
        this.setState({
          modal_componet: (
            <div>Something went wrong, please try again later</div>
          ),
        });
      }
    }
    return true;
  }

  onChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  submitHandler = () => {
    const { title, body, publish_at, deleted_images, category_id } = this.state;
    const { admin_upload_images_finished_array } = this.props;
    const images_field = admin_upload_images_finished_array.join(",");
    console.log(title, body);
    const { writing } = this.props.location;
    writing.title = title;
    writing.body = body;
    writing.category_id = category_id;
    writing.publish_at = `${publish_at.getFullYear()}-${
      publish_at.getMonth() + 1
    }-${publish_at.getDate()}`;
    writing.image_urls = images_field;
    console.log("writing", writing);
    const { update_writing, delete_image } = this.props;
    update_writing(writing);
    deleted_images.forEach((image) => {
      delete_image({ image });
    });
    this.setState({ is_modal_open: true });
  };

  render() {
    const { title, body, is_modal_open, modal_componet, category_id } =
      this.state;
    const { admin_upload_images_finished_array, writing_categories } =
      this.props;
    return (
      <>
        <div className="admin-images-list-main">
          <div className="admin-header mb-5">Update writing</div>
          <Form>
            <FormGroup row>
              <Label for="title" sm={2}>
                Publish
              </Label>
              <Col sm={10}>
                <DatePicker
                  className="form-control"
                  selected={this.state.publish_at}
                  onChange={(date) => this.setState({ publish_at: date })}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="exampleSelect" sm={2}>
                Category
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="category_id"
                  id="exampleSelect"
                  value={category_id}
                  onChange={this.onChange}
                >
                  {writing_categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="title" sm={2}>
                Title
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="title"
                  id="post-title"
                  placeholder="Enter title"
                  value={title}
                  onChange={this.onChange}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="title" sm={2}>
                Images
              </Label>
              <Col sm={10}>
                <div>
                  <FileDrop
                    onFrameDragEnter={(event) =>
                      console.log("onFrameDragEnter", event)
                    }
                    onFrameDragLeave={(event) =>
                      console.log("onFrameDragLeave", event)
                    }
                    onFrameDrop={(event) => console.log("onFrameDrop", event)}
                    onDragOver={(event) => console.log("===onDragOver", event)}
                    onDragLeave={(event) =>
                      console.log("===onDragLeave", event)
                    }
                    onDrop={(files, event) => {
                      console.log("onDrop!", files, event, files.length);
                      const allowedType = [
                        "image/png",
                        "image/jpeg",
                        "image/gif",
                      ];
                      // // Array.from(files).forEach((image) => {
                      [...files].forEach((image) => {
                        if (allowedType.includes(image.type)) {
                          const fileReader = new FileReader();
                          fileReader.readAsDataURL(image);
                          fileReader.onload = () => {
                            // const file_result = fileReader.result;
                            // this.setState((prevState) => {
                            //   return {
                            //     ...prevState,
                            //     images: [...prevState.images, file_result],
                            //   };
                            // });
                            const { upload_image } = this.props;
                            const payload = {
                              file: image,
                            };
                            console.log("file_result", image);

                            upload_image(payload);
                          };
                        } else {
                          alert(
                            "Please only upload image files(gif, jpeg, png)"
                          );
                        }
                      });
                      // const allowedImages = [...files].filter((image) =>
                      //   allowedType.includes(image.type)
                      // );
                      // const { upload_image } = this.props;
                      // const payload = {
                      //   file: allowedImages,
                      // };
                      // upload_image(payload);
                      // console.log("===");
                    }}
                  >
                    Drop image files here!
                  </FileDrop>
                </div>
                <div className="upload-image-div">
                  {admin_upload_images_finished_array &&
                    admin_upload_images_finished_array.map(
                      (image_name, index) => {
                        return (
                          <div
                            className="upload-image-div-item"
                            key={image_name}
                          >
                            <img
                              alt={index}
                              src={`${BASE_URL}/cms-system/uploads/writing/${image_name}`}
                              height="120px"
                              width="auto"
                            />
                            <CopyToClipboard
                              key={image_name}
                              text={`![100%](${BASE_URL}/cms-system/uploads/writing/${image_name})`}
                              onCopy={() => this.setState({ copied: true })}
                            >
                              <div
                                className="copy-to-clipboard"
                                title="Copy link to clipboard"
                              >
                                <span className="material-icons">
                                  content_copy
                                </span>
                              </div>
                            </CopyToClipboard>
                            <div
                              className="delete-selected-icon-div"
                              title="Delete"
                              onClick={() => {
                                const {
                                  admin_upload_images_finished_array,
                                  update_admin_uploaded_images_array,
                                  // delete_image,
                                } = this.props;
                                const image_name =
                                  admin_upload_images_finished_array[index];
                                this.setState((prevState) => {
                                  const new_deleted_images = [
                                    ...prevState.deleted_images,
                                    image_name,
                                  ];
                                  return {
                                    ...prevState,
                                    deleted_images: new_deleted_images,
                                  };
                                });
                                console.log(
                                  "admin_upload_images_finished_array",
                                  admin_upload_images_finished_array,
                                  index
                                );
                                admin_upload_images_finished_array.splice(
                                  index,
                                  1
                                );
                                update_admin_uploaded_images_array(
                                  admin_upload_images_finished_array
                                );
                              }}
                            >
                              <span className="material-icons">
                                delete_outline
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="body" sm={2}>
                Description
              </Label>
              <Col sm={10}>
                <Input
                  type="textarea"
                  name="body"
                  id="post-body"
                  placeholder="Enter description"
                  value={body}
                  onChange={this.onChange}
                />
              </Col>
            </FormGroup>

            <div className="">
              <Button
                onClick={this.submitHandler}
                color="primary"
                disabled={title.trim().length < 2 || body.trim().length < 2}
              >
                UPDATE
              </Button>
            </div>
          </Form>
        </div>
        <Modal isOpen={is_modal_open} className="admin-reorder-modal">
          <ModalBody>{modal_componet}</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.setState({ is_modal_open: false });
                const { push } = this.props.history;
                push({
                  pathname: "/admin/published",
                });
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    writing_categories: state.writing_categories.writing_categories,
    writings: state.writing.writings,
    // news: state.news.news,
    admin_update_writing_status: state.message.admin_update_writing_status,
    admin_upload_images_finished_array:
      state.message.admin_upload_images_finished_array,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_writing_categories: () => dispatch(actions.get_writing_categories()),
    update_writing: (payload) => dispatch(actions.update_writing(payload)),
    upload_image: (payload) => dispatch(actions.upload_image(payload)),
    init_admin_upload_images_finished_array: () =>
      dispatch(
        actions.set_message({
          name: "admin_upload_images_finished_array",
          value: [],
        })
      ),
    update_admin_uploaded_images_array: (new_array) => {
      dispatch(
        actions.set_message({
          name: "admin_upload_images_finished_array",
          value: [...new_array],
        })
      );
    },
    delete_image: (image_name) => dispatch(actions.delete_image(image_name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateWriting);
