import * as actionTypes from "./actionTypes";

export const get_writings = () => {
  console.log("get_writings action");
  return {
    type: actionTypes.GET_WRITINGS,
  };
};

export const set_writings = (payload) => {
  console.log("set_writings action", payload);
  return {
    type: actionTypes.SET_WRITINGS,
    payload,
  };
};

export const delete_writing = (payload) => {
  console.log("delete_writing action", payload);
  // payload : id , url
  return {
    type: actionTypes.DELETE_WRITING,
    payload,
  };
};

export const add_writing = (payload) => {
  console.log("add_writing action", payload);
  // payload
  return {
    type: actionTypes.ADD_WRITING,
    payload,
  };
};

export const update_writing = (payload) => {
  console.log("update_writing action", payload);
  // payload
  return {
    type: actionTypes.UPDATE_WRITING,
    payload,
  };
};

export const upload_image = (payload) => {
  // console.log("upload_image action", payload);
  // payload
  return {
    type: actionTypes.UPLOAD_IMAGE,
    payload,
  };
};

export const delete_image = (payload) => {
  console.log("delete_image action", payload);
  // payload
  return {
    type: actionTypes.DELETE_IMAGE,
    payload,
  };
};

export const check_writing_files = () => {
  // console.log("check_writing_files action");
  // payload
  return {
    type: actionTypes.CHECK_WRITING_FILES,
  };
};
