import React from "react";

import bio_image_background from "../../../../images/Eni4SmugMug-CAL00263-Edit-1.jpg";
import Tools from "../../../../utils/tools";

function PhotoArtBio(props) {
  return (
    <div
      className={`bio-text ${
        Tools.is_less_than(1200) && !Tools.is_less_than(1000) ? "container" : ""
      }`}
    >
      <br />
      <br />

      <img
        src={bio_image_background}
        alt="bio profile"
        className="bio-img-background"
        width="100%"
      />
      {!Tools.is_less_than(1000) && (
        <>
          <br />
          <br />
        </>
      )}
      <p>Why do I take pictures?</p>
      <p>
        In almost everything I do, I can’t help but be driven by a search for
        beauty.
      </p>
      <p>
        This perception of beauty moves me, whether in shapes of tree branches
        against clouds in the sky or incredible mathematical constructs designed
        from abstract building blocks of logic.
      </p>
      <p>
        When it comes to the natural world around us, from birds standing by a
        lakeshore to a squirrel doing ‘yoga’ in the sun, the world is worth
        noticing.
      </p>
      <p>
        In my pictures I try to capture what I feel when something catches my
        eye. Something in the landscape, light, composition touches me. I take
        the picture and process it because it speaks to me. I hope through these
        galleries I can share some those same feelings with others.
      </p>
      <p>
        This is how I see the world today. Tomorrow my vision of beauty and
        meaning will likely change. I am trying to enjoy the journey while it
        lasts.
      </p>
    </div>
  );
}

export default PhotoArtBio;
