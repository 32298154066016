import axios from "axios";
import { put, select } from "redux-saga/effects";

import { BASE_URL } from "../../consts/const";
import * as actions from "../actions/index";

export function* getWritingsSaga(action) {
  console.log("i am getWritingsSaga", action);

  try {
    // const token = localStorage.getItem("token");
    const header = {
      headers: {
        "Content-Type": "application/ix-www-form-urlencoded",
        // Authorization: `Bearer ${token || ""}`,
      },
    };

    const response = yield axios.get(
      `${BASE_URL}/cms-system/api/writing/read.php`,
      header
    );
    console.log("response get", response);
    if (Array.isArray(response.data)) {
      yield put(actions.set_writings(response.data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addWritingSaga(action) {
  console.log("i am addWritingSaga", action);
  const { payload } = action;
  console.log("payload", payload);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/ix-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  yield put(
    actions.set_message({
      name: "admin_add_writing_status",
      value: "pending",
    })
  );

  try {
    payload.url = "";
    payload.size = 10;
    const response = yield axios.post(
      `${BASE_URL}/cms-system/api/writing/create.php`,
      payload,
      header
    );
    console.log("response create writing", response);
    yield put(actions.get_writings());
    yield put(
      actions.set_message({
        name: "admin_add_writing_status",
        value: "done",
      })
    );
    // }
  } catch (err) {
    // console.log("err==1", err.status);
    yield put(
      actions.set_message({
        name: "admin_add_writing_status",
        value: "failure",
      })
    );
  }
}

export function* updateWritingSaga(action) {
  console.log("i am updateWritingSaga", action);
  const { payload } = action;
  console.log("payload", payload);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/ix-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };
  yield put(
    actions.set_message({
      name: "admin_update_writing_status",
      value: "pending",
    })
  );

  try {
    const response = yield axios.post(
      `${BASE_URL}/cms-system/api/writing/update.php`,
      payload,
      header
    );
    console.log("response update writing", response);
    yield put(actions.get_writings());
    yield put(
      actions.set_message({
        name: "admin_update_writing_status",
        value: "done",
      })
    );
  } catch (err) {
    yield put(
      actions.set_message({
        name: "admin_update_writing_status",
        value: "failure",
      })
    );
  }
}

export function* deleteWritingSaga(action) {
  console.log("i am deleteWritingSaga", action);
  const { payload } = action;
  try {
    const token = localStorage.getItem("token");
    const header = {
      headers: {
        "Content-Type": "application/ix-www-form-urlencoded",
        Authorization: `Bearer ${token || ""}`,
      },
    };
    const response = yield axios.post(
      `${BASE_URL}/cms-system/api/writing/delete.php`,
      payload,
      header
    );
    yield put(actions.get_writings());
    console.log("response delete writing", response);
  } catch (err) {
    console.log(err);
  }
}

export function* uploadImagesSaga(action) {
  console.log("i am uploadImagesSaga", action);
  const { payload } = action;
  console.log("payload", payload);
  const { file } = action.payload;
  console.log(file);

  try {
    const token = localStorage.getItem("token");
    const header = {
      headers: {
        "Content-Type": "application/ix-www-form-urlencoded",
        Authorization: `Bearer ${token || ""}`,
      },
    };

    const formData = new FormData();
    formData.append("upload", "upload");
    formData.append("file", file);
    console.log("formData", formData);
    let response = yield axios.post(
      `${BASE_URL}/cms-system/api/writing/upload.php`,
      formData,
      header
    );

    // const { data, status } = response;
    const { data, status } = response;
    if (status === 201) {
      console.log("response----", response.status, data);
      const test = data.split("|||||");
      console.log("response upload images", test[1]);
      const state = yield select();
      console.log(
        "response upload images==",
        state.message.admin_upload_images_finished_array
      );
      const admin_upload_images_finished_array =
        state.message.admin_upload_images_finished_array;
      if (admin_upload_images_finished_array) {
        const new_array = [...admin_upload_images_finished_array, test[1]];
        yield put(
          actions.set_message({
            name: "admin_upload_images_finished_array",
            value: new_array,
          })
        );
      }
    }

    // console.log("response upload images", response);
  } catch (err) {
    console.log(err);
  }
}

export function* deleteImageSaga(action) {
  console.log("i am deleteImageSaga", action);
  const { payload } = action;
  try {
    const token = localStorage.getItem("token");
    const header = {
      headers: {
        "Content-Type": "application/ix-www-form-urlencoded",
        Authorization: `Bearer ${token || ""}`,
      },
    };
    const response = yield axios.post(
      `${BASE_URL}/cms-system/api/writing/delete_image.php`,
      payload,
      header
    );
    console.log("response delete image", response);
  } catch (err) {
    // console.log(err);
  }
}

export function* checkWritingFilesSaga(action) {
  console.log("i am checkWritingFilesSaga", action);
  // const { payload } = action;
  try {
    const token = localStorage.getItem("token");
    const header = {
      headers: {
        "Content-Type": "application/ix-www-form-urlencoded",
        Authorization: `Bearer ${token || ""}`,
      },
    };
    const response = yield axios.post(
      `${BASE_URL}/cms-system/api/writing/check_files.php`,
      header
    );
    console.log("response check files", response);
  } catch (err) {
    // console.log(err);
  }
}
