import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { stack as BargerMenu } from "react-burger-menu";

import name_logo from "../../../../images/EF-Initials.png";
import * as actions from "../../../../store/actions/index";

import "./mMenu.css";
import "../../../css/ef/eMenu.css";
import {
  INDEX_ROOT_URL,
  PHOTO_ART_ROOT_URL,
  // WRITE_UP_ROOT_URL,
  // WRITING_ROOT_URL,
} from "../../../../consts/const";
import Tools from "../../../../utils/tools";

class MobileMenu extends React.Component {
  state = {
    menuOpen: false,
  };
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false });
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }

  create_comparator = (key) => {
    return (a, b) => {
      let val_a = a[key];
      let val_b = b[key];

      if (val_a < val_b) {
        return 1;
      }
      if (val_a > val_b) {
        return -1;
      }
      return 0;
    };
  };

  sortByID = () => {
    return (a, b) => {
      if (a.id === "1" && b.id !== "1") {
        return -1;
      }
      return 0;
    };
  };
  render() {
    const path = window.location.pathname;
    // const isPhotoArtPath = path.includes("photography");
    const isWritingsPath = path.includes("writings");
    const isHomePath = Tools.is_root_domain();

    const { posts, writings } = this.props;
    if (posts.length === 0 || writings.length === 0) {
      return null;
    }
    const post_items = posts.filter((post) => {
      return (
        post.gallery === "1" &&
        post.delete !== "1" &&
        post.category_id !== "1" &&
        post.location_id !== "1"
      );
    });
    // const { category } = this.state;
    post_items.sort(this.create_comparator("category_display_id"));
    post_items.sort(this.sortByID());
    console.log("items", post_items);

    const writings_1 = writings.filter((item) => {
      return item.delete !== "1" && item.category_id !== "1";
    });

    const writings_items = Tools.getUniqueArrayBy(writings_1, "category_id");

    writings_items.sort(this.create_comparator("category_display_id"));
    writings_items.sort(this.sortByID());

    console.log(
      "writings_items",
      Tools.is_photoart_domain(),
      window.location.host
    );

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");
    console.log(id);

    return (
      <div className="mobile-menu-main-div">
        <div>
          <div className="menu-item-box">
            <a href={INDEX_ROOT_URL}>
              <img src={name_logo} alt="name logo" width="115px" />
            </a>
          </div>
          <div className="menu-item-box-1">
            <BargerMenu
              isOpen={this.state.menuOpen}
              onStateChange={(state) => this.handleStateChange(state)}
              right
            >
              {!Tools.is_root_domain() && (
                <a
                  id="home"
                  className={`menu-item ${isHomePath ? "active-item" : ""}`}
                  href={INDEX_ROOT_URL}
                >
                  HOME
                </a>
              )}
              {Tools.is_root_domain() && (
                <Link
                  to="/"
                  onClick={() => {
                    this.closeMenu();
                  }}
                  className={`menu-item ${!id ? "active-item" : ""}`}
                >
                  HOME
                </Link>
              )}
              <div className="mt-4"></div>

              {/* <Link
                onClick={() => {
                  console.log("clickclick");
                  this.closeMenu();
                }}
                className="menu-item"
                to={`/`}
              >
                Home
              </Link> */}
              {Tools.is_photoart_domain() && (
                <>
                  {/* <br /> */}
                  {/* <div className="mt-4"></div> */}
                  {/* {!id && (
                    <div className="menu-item only-text">PHOTOGRAPHY</div>
                  )}
                  {!!id && (
                    <div className="menu-item only-text">
                      <Link
                        to="/"
                        onClick={() => {
                          const { set_is_gallery_true } = this.props;
                          set_is_gallery_true();
                          console.log("clickclick");
                          this.closeMenu();
                        }}
                      >
                        PHOTOGRAPHY
                      </Link>
                    </div>
                  )} */}

                  <div className="menu-item only-text">
                    <Link
                      to="/"
                      onClick={() => {
                        const { set_is_gallery_true } = this.props;
                        set_is_gallery_true();
                        console.log("clickclick");
                        this.closeMenu();
                      }}
                      className={`menu-item ${!id ? "active-item" : ""}`}
                    >
                      PHOTOGRAPHY
                    </Link>
                  </div>

                  {/* <div className="menu-item only-text">PHOTOGRAPHY</div> */}
                  <hr />
                  {post_items.map((item) => {
                    return (
                      <Link
                        onClick={() => {
                          const { set_is_gallery_true } = this.props;
                          set_is_gallery_true();
                          console.log("clickclick");
                          this.closeMenu();
                        }}
                        key={item.id}
                        // classNames="menu-item active-item"
                        className={`menu-item ${
                          id === item.category_id ? "active-item" : ""
                        }`}
                        to={`/photography/gallery?id=${item.category_id}`}
                      >
                        {item.category_name}
                      </Link>
                    );
                  })}
                </>
              )}

              {/* <br /> */}
              {/* <a id="writings" className="menu-item" href={INDEX_ROOT_URL}>
                WRITINGS
              </a> */}

              {isWritingsPath && (
                <>
                  <div className="menu-item only-text">WRITING CATEGORY</div>
                  <hr />

                  {writings_items.map((item) => {
                    return (
                      <Link
                        onClick={() => {
                          const { set_is_gallery_true } = this.props;
                          set_is_gallery_true();
                          console.log("clickclick");
                          this.closeMenu();
                        }}
                        key={item.id}
                        className="menu-item"
                        to={`/writings/${item.category_id}`}
                      >
                        {item.category_name}
                      </Link>
                    );
                  })}
                </>
              )}

              {isHomePath && (
                <>
                  <a
                    id="writings"
                    // classNames="menu-item active-item"
                    className={`menu-item ${!isHomePath ? "active-item" : ""}`}
                    href={PHOTO_ART_ROOT_URL}
                  >
                    PHOTOGRAPHY
                  </a>
                  {/* <br /> */}

                  {/* <a
                    id="writings"
                    className="menu-item"
                    href={WRITING_ROOT_URL}
                  >
                    WRITINGS
                  </a> */}
                </>
              )}

              {/* <br /> */}
              <div className="mt-4 mb-3 pt-3 pb-3"></div>
              <a href="mailto: eniko@enikofodor.com" className="menu-item">
                eniko<span className="email-at">@</span>enikofodor.com
              </a>
            </BargerMenu>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.post.posts,
    writings: state.writing.writings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    set_is_gallery_true: () =>
      dispatch(
        actions.set_message({
          name: "is_gallery",
          value: true,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
