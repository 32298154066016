import * as actionTypes from "./actionTypes";

export const get_categories = () => {
  console.log("get_categories action");
  return {
    type: actionTypes.GET_CATEGORIES,
  };
};

export const set_categories = (payload) => {
  // console.log("set_categories action");
  return {
    type: actionTypes.SET_CATEGORIES,
    payload,
  };
};

export const add_category = (payload) => {
  // payload {name: catetory}
  console.log("add_category action");
  return {
    type: actionTypes.ADD_CATEGORY,
    payload,
  };
};

export const update_category = (payload) => {
  // payload {name: catetory}
  console.log("update_category action");
  return {
    type: actionTypes.UPDATE_CATEGORY,
    payload,
  };
};

export const delete_category = (payload) => {
  // payload {name: catetory}
  console.log("delete_category action", payload, "==");
  return {
    type: actionTypes.DELETE_CATEGORY,
    payload,
  };
};

export const update_category_order = (payload) => {
  // payload {name: catetory}
  console.log("update_category_order action", payload, "==");
  return {
    type: actionTypes.UPDATE_CATEGORY_ORDER,
    payload,
  };
};
