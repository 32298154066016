import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Route,
  Switch,
  // useRouteMatch
} from "react-router-dom";
import { Container, Row, Col, Collapse } from "reactstrap";

import { Link } from "react-router-dom";

import CreatePost from "./CreatePost";
import UpdatePost from "./UpdatePost";
import CreateCategory from "./CreateCategory";
import CreateLocation from "./CreateLocation";
import ShowImages from "./ShowImages";
import ShowImagesTrash from "./ShowImagesTrash";

// import WritingCategory_1 from "./writing/CreateWritingCategory";
import WritingCategory from "./writing/WritingCategory";
import Published from "./writing/Published";
import CreateWriting from "./writing/CreateWriting";
import UpdateWriting from "./writing/UpdateWriting";
import Unpublished from "./writing/Unpublished";

import SortImage from "./Sort";
// import Login from "./Login";
import Logout from "./logout";
import * as actions from "../../store/actions/index";

import "./dashboard.css";

const Dashboard = (props) => {
  const [isPhotoOpen, setIsPhotoOpen] = useState(false);
  const toggle_photo_section = () => {
    setIsPhotoOpen(!isPhotoOpen);
    setIsWritingOpen(false);
  };
  const [isWritingOpen, setIsWritingOpen] = useState(false);
  const toggle_writing_section = () => {
    setIsWritingOpen(!isWritingOpen);
    setIsPhotoOpen(false);
  };
  // let { path, url } = useRouteMatch();
  const { logout } = props;
  // console.log("path, url", path, url);
  const path = window.location.pathname;
  const is_sort_path = path === "/admin/sort";
  return (
    <>
      <Container className="bashboard-container">
        <Row className="dashboard-main-row">
          <Col xl={`${is_sort_path ? "0" : "3"}`} className="left-panel">
            <div className="dash-board-menu">
              <div>MENU</div>
              <div
                onClick={toggle_photo_section}
                className="collapse-main-section"
              >
                Photo Section{" "}
                <span className="material-icons">
                  {isPhotoOpen ? "expand_less" : "expand_more"}
                </span>
              </div>
              <Collapse isOpen={isPhotoOpen} className="menu-collapse">
                <div>
                  <Link to="/admin/post">Home</Link>
                </div>
                <div>
                  <Link to="/admin/sort">Sort Images</Link>
                </div>
                <div>
                  <Link to="/admin/category">Galleries</Link>
                </div>
                <div>
                  <Link to="/admin/location">Locations</Link>
                </div>
                <div>
                  <Link to="/admin/post/trash">Trash</Link>
                </div>
              </Collapse>
              <div
                className="collapse-main-section"
                onClick={toggle_writing_section}
              >
                Writing Section{" "}
                <span className="material-icons">
                  {isWritingOpen ? "expand_less" : "expand_more"}
                </span>
              </div>
              <Collapse isOpen={isWritingOpen} className="menu-collapse">
                <div>
                  <Link to="/admin/published">Published</Link>
                </div>
                <div>
                  <Link to="/admin/writing_category">Category</Link>
                </div>
                <div>
                  <Link to="/admin/unpublished">Unpublished</Link>
                </div>
              </Collapse>

              <div>
                {/* <Link to="/admin/logout">Logout</Link> */}
                <Link to={{ pathname: `/admin/logout`, logout }}>Logout</Link>
              </div>
            </div>
          </Col>
          <Col className="right-panel">
            <Switch>
              <Route path="/admin/post" exact component={ShowImages} />
              <Route path="/admin/sort" exact component={SortImage} />
              <Route path="/admin/category" component={CreateCategory} />
              <Route path="/admin/location" component={CreateLocation} />
              <Route path="/admin/post/add" component={CreatePost} />
              <Route path="/admin/post/update" component={UpdatePost} />
              <Route path="/admin/post/trash" component={ShowImagesTrash} />
              {/* published area */}
              <Route path="/admin/published/update" component={UpdateWriting} />
              <Route path="/admin/published/add" component={CreateWriting} />
              <Route path="/admin/published" component={Published} />
              <Route
                path="/admin/writing_category"
                component={WritingCategory}
              />
              <Route path="/admin/unpublished" component={Unpublished} />
              <Route path="/admin/logout" component={Logout} />

              <Route path="/admin" component={ShowImages} />
            </Switch>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    //   is_auth: () => dispatch(actions.is_auth()),
    logout: () => dispatch(actions.logout()),
  };
};
export default connect(null, mapDispatchToProps)(Dashboard);
