import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";

import * as actions from "../../store/actions/index";
import { BASE_URL } from "../../consts/const";

class UpdatePost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewUrl: null,
      file: null,
      isValid: false,
      post: null,
    };
    this.filePickerRef = React.createRef();
    // this.focusTextInput = this.focusTextInput.bind(this);
  }

  //   state = {
  //     previewUrl: null,
  //     file: null,
  //     isValid: false,
  //     post: null,
  //   };

  componentDidMount() {
    const { get_categories, get_locations, init_admin_update_post_status } =
      this.props;
    init_admin_update_post_status();
    get_categories();
    get_locations();
    console.log("abc", this.props);
    const { post } = this.props.location;
    console.log("ddd", post);

    this.setState({
      post: { ...post },
    });

    const { file } = this.state;

    if (!file) {
      return;
    }
    console.log("file", file.size);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      // setPreviewUrl(fileReader.result);
      this.setState({
        previewUrl: fileReader.result,
      });
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { file } = this.state;
    if (file !== prevState.file) {
      if (!file) {
        return;
      }
      console.log("file", file.size);
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        // setPreviewUrl(fileReader.result);
        this.setState({
          previewUrl: fileReader.result,
        });
      };
    }
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    // console.log("name , valuse", name, value);
    this.setState({
      ...this.state,
      post: {
        ...this.state.post,
        [name]: value,
      },
    });
  };

  submitHandler = async () => {
    const { update_post } = this.props;
    const { post, file, width, height } = this.state;

    // const payload = {
    //   file,
    //   post,
    // };
    console.log("height, width", height, "--", width, post);
    if (height && width) {
      post["image_height"] = height;
      post["image_width"] = width;
    }
    console.log("height, width", height, "-change?-", width, post);

    update_post(post, file);
  };

  onload_image = ({ target: img }) => {
    this.setState({
      height: img.offsetHeight,
      width: img.offsetWidth,
    });

    console.log("file==", img.offsetHeight, img.offsetWidth);
  };

  pickImageHandler = () => {
    console.log("ddd");
    this.filePickerRef.current.click();
  };

  pickedHandler = (event) => {
    console.log(event.target);
    const { isValid } = this.state;
    let pickedFile;
    let fileIsValid = isValid;

    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      this.setState({
        file: pickedFile,
        isValid: true,
      });
      fileIsValid = true;
    } else {
      this.setState({
        isValid: false,
      });
      fileIsValid = false;
    }

    // if (event.target.files && event.target.files.length === 1) {
    //   pickedFile = event.target.files[0];
    //   setFile(pickedFile);
    //   setIsValid(true);
    //   fileIsValid = true;
    // } else {
    //   setIsValid(false);
    //   fileIsValid = false;
    // }
    console.log("pickedFile, fileIsValid", pickedFile, fileIsValid);
  };

  render() {
    const { post, previewUrl } = this.state;
    console.log("post render =====", post);
    if (!post) {
      return null;
    }

    const { title, body, category_id, location_id, url, tags } = post;
    console.log("category render =====", category_id);
    const {
      categories,
      locations,
      admin_update_post_success,
      admin_update_post_failed,
    } = this.props;
    // const {  file, isValid } = this.state;

    console.log("admin_update_post_success", admin_update_post_success);
    return (
      <>
        <div>
          <div className="admin-header mb-5">Update Image</div>
          <Form>
            <FormGroup row>
              <Label for="title" sm={2}>
                Title
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Enter title"
                  value={title}
                  onChange={this.onChangeHandler}
                  //   onChange={this.onChangeTitleHandler}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="body" sm={2}>
                Description
              </Label>
              <Col sm={10}>
                <Input
                  // type="textarea"
                  type="text"
                  name="body"
                  id="body"
                  placeholder="Enter description"
                  value={body}
                  onChange={this.onChangeHandler}
                  //   onChange={this.onChangeBodyHandler}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="tags" sm={2}>
                Tags
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="tags"
                  id="tags"
                  placeholder="Use comma to separate each tag"
                  value={tags}
                  onChange={this.onChangeHandler}
                  //   onChange={this.onChangeBodyHandler}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="category_id" sm={2}>
                Gallery
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="category_id"
                  id="category_id"
                  value={category_id}
                  //   onChange={this.onChangeCategoryHandler}
                  onChange={this.onChangeHandler}
                >
                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="location_id" sm={2}>
                Location
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="location_id"
                  id="location_id"
                  value={location_id}
                  //   onChange={this.onChangeLocationHandler}
                  onChange={this.onChangeHandler}
                >
                  {locations.map((location) => {
                    return (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>

            <FormGroup>
              {/* <Col sm={6}> */}
              <input
                ref={this.filePickerRef}
                type="file"
                accept="image/gif, image/jpeg, image/png"
                style={{ display: "none" }}
                onChange={this.pickedHandler}
              />
              <div className="mb-2">
                {previewUrl && (
                  <img
                    src={previewUrl}
                    alt="Preview"
                    width="500px"
                    onLoad={this.onload_image}
                  />
                )}
                {!previewUrl && (
                  //   <p onClick={this.pickImageHandler}>Please pick an image.</p>
                  <img
                    src={`${BASE_URL}/cms-system/uploads/${url}`}
                    alt="prev pic"
                    width="500px"
                    onLoad={this.onload_image}
                  />
                )}
              </div>
              <Button
                outline
                color="primary"
                type="button"
                onClick={this.pickImageHandler}
              >
                PICK IMAGE
              </Button>
            </FormGroup>
            <div className="">
              <Button
                onClick={this.submitHandler}
                color="primary"
                // disabled={
                //   !file || title.trim().length < 10 || body.trim().length < 10
                // }
              >
                SUBMIT
              </Button>
            </div>
          </Form>
          {admin_update_post_success && (
            <div className="mt-4">
              <Alert color="success">
                Image updated! <Link to="/admin/post">Back</Link>
              </Alert>
            </div>
          )}
          {admin_update_post_failed && (
            <div className="mt-4">
              <Alert color="warning">
                {admin_update_post_failed} <Link to="/admin/post">Back</Link>
              </Alert>
            </div>
          )}
        </div>
      </>
    );

    // return (
    //   <>
    //     <div>update post</div>

    //   </>
    // );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.category.categories,
    locations: state.location.locations,
    admin_update_post_success: state.message.admin_update_post_success,
    admin_update_post_failed: state.message.admin_update_post_failed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_categories: () => dispatch(actions.get_categories()),
    get_locations: () => dispatch(actions.get_locations()),
    update_post: (payload, file) =>
      dispatch(actions.update_post(payload, file)),
    init_admin_update_post_status: () =>
      dispatch(
        actions.set_message({
          name: "admin_update_post_success",
          value: false,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePost);
