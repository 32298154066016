// export const BASE_URL = "http://192.168.0.102";
// export const BASE_URL = "http://192.168.0.139";
//  export const BASE_URL = "http://192.168.29.227";
// export const BASE_URL = "http://192.168.0.241";

// export const BASE_URL = "https://adityoprakash.com";
export const BASE_URL = "https://photography.enikofodor.com";

export const IS_LOCAL = false;

// const local_link = "http://localhost:";
const local_link = "http://192.168.0.241:";

export const INDEX_ROOT_URL = IS_LOCAL
  ? local_link + "3000/"
  : "https://www.enikofodor.com/";
export const PHOTO_ART_ROOT_URL = IS_LOCAL
  ? local_link + "3001"
  : "https://photography.enikofodor.com";

export const WRITING_ROOT_URL = IS_LOCAL
  ? local_link + "3002"
  : "https://writings.enikofodor.com";

// http://localhost:3001/photo-art
// export const IS_ROOT_DOMAIN = IS_LOCAL ?

// /home/adityopr/public_html/enikofodor

// # Use PHP5.4 as default
// # AddHandler application/x-httpd-php54 .php

// # php -- BEGIN cPanel-generated handler, do not edit
// # Set the “ea-php74” package as the default “PHP” programming language.
// <IfModule mime_module>
//   AddHandler application/x-httpd-ea-php74 .php .php7 .phtml
//   Options -MultiViews
//   RewriteEngine On
//   RewriteCond %{REQUEST_FILENAME} !-f
//   RewriteRule ^ index.html [QSA,L]
// </IfModule>
// # php -- END cPanel-generated handler, do not edit
