import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { Photo } from "react-photo-gallery";
import Gallery from "react-photo-gallery";
import // BrowserView,
// MobileView,
// isTablet,
// isBrowser,
// isMobile,
"react-device-detect";

// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import Tools from "../../utils/tools";

import * as actions from "../../store/actions/index";

// import Menu from "./components/Menu";
// import Bio from "./components/Bio";
// import Foot from "./components/Foot";
// import PhotoArtTopImgMain from "./components/PhotoArtTopImgMain";

import "./PhotoArt.css";
import "./Gallery.css";
import "../../components/css/media/gallery.css";
import "./components/mobile/Gallery.css";
import "../css/ef/Gallery.css";
import { BASE_URL } from "../../consts/const";

import NewSlideShow from "./components/NewSlideShow";

import Tools from "../../utils/tools";

const PHOTO_MARGIN = 5;

class MyGallery extends React.Component {
  state = {
    home_index: null,
    items: [],
    is_visible: false,
    // isGallery: true,
    is_desktop: true,
  };

  componentDidMount() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const p_id = params.get("pid");
    console.log("p_id", p_id);
    this.setGallery(!p_id);
    // go to top start
    // if (isTablet || isMobile) {
    // var scrollComponent = this;
    // document.addEventListener("scroll", function (e) {
    //   scrollComponent.toggleVisibility();
    // });
    document.addEventListener("scroll", this.toggleVisibility_fn);
    // }
    // go to top end

    this.setState({
      is_desktop: !Tools.is_less_than(1000),
    });
    // window.addEventListener("resize", () => {
    //   // console.log(Tools.GetWindowWidth());
    //   this.setState({
    //     is_desktop: !Tools.is_less_than(1000),
    //   });
    // });
    window.addEventListener("resize", this.resize_fn);

    // console.log("history", this.props);
    // console.log("===444");
    const { get_posts } = this.props;
    get_posts();
    window.scrollTo(0, 0);
    document.addEventListener("keydown", this.moveImageByKeyFunc, false);
    this.get_posts();
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.toggleVisibility_fn);
    window.removeEventListener("resize", this.resize_fn);
    document.removeEventListener("keydown", this.moveImageByKeyFunc, false);
    const { set_is_gallery } = this.props;
    set_is_gallery(true);
  }

  resize_fn = () => {
    this.setState({
      is_desktop: !Tools.is_less_than(1000),
    });
  };

  toggleVisibility_fn = () => {
    var scrollComponent = this;
    scrollComponent.toggleVisibility();
  };

  moveImageByKeyFunc = (event) => {
    // console.log(event.keyCode);
    // if (event.keyCode === 37 && this.state.fullScreenState) {
    if (event.keyCode === 37 && this.state.fullScreenState) {
      console.log(event.keyCode);
      this.onClickImage(0);
    }
    if (event.keyCode === 39 && this.state.fullScreenState) {
      console.log(event.keyCode);
      this.onClickImage(1);
    }
  };

  componentDidUpdate(prevProps) {
    // console.log("key==", prevProps.location.search, this.props.location.search);
    // if (prevProps.location.search !== this.props.location.search) {
    //   this.get_posts();
    // }

    if (prevProps.location.search !== this.props.location.search) {
      console.log("componentDidUpdate okok", this.props.location.search);
      const { location } = this.props;
      const params = new URLSearchParams(location.search);
      const p_id = params.get("pid");
      if (!p_id) {
        this.get_posts();
        this.setGallery(!p_id);
      }
    }
  }

  get_posts = () => {
    // console.log("okok");
    this.setState({
      home_index: null,
    });
    const { posts } = this.props;
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const cat_id = params.get("id");
    this.setState({
      cat_id,
    });

    const items = posts.filter((post) => {
      return (
        post.category_id === cat_id &&
        post.delete !== "1" &&
        post.location_id !== "1" &&
        post.category_id !== "1"
      );
    });

    const home_post_index = items.findIndex((post) => {
      return post.category_id === cat_id && post.gallery === "1";
    });
    console.log("okok", home_post_index);
    this.setState({
      home_index: home_post_index,
      items,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.posts !== this.props.posts) {
      console.log("okok");
      this.setState({
        home_index: null,
      });
      const { posts } = nextProps;
      const { location } = this.props;
      const params = new URLSearchParams(location.search);
      const cat_id = params.get("id");

      const items = posts.filter((post) => {
        return (
          post.category_id === cat_id &&
          post.delete !== "1" &&
          post.location_id !== "1" &&
          post.category_id !== "1"
        );
      });


      // const home_post_index = items.findIndex((post) => {
      //   return post.category_id === cat_id && post.gallery === "1";
      // });
      const p_id = params.get("pid");
      let home_post_index = items.findIndex((post) => {
        return post.category_id === cat_id && post.id === p_id;
      });
      if (home_post_index === -1) {
        home_post_index = items.findIndex((post) => {
          return post.category_id === cat_id && post.gallery === "1";
        });
      }

      console.log("okok", home_post_index);
      this.setState({
        home_index: home_post_index,
        items,
      });
      // this.get_posts();
    }
    return true;
  }

  // onClickImage = (left_or_right) => {
  //   console.log("left or right", left_or_right);
  //   this.setState((state) => {
  //     let new_index;
  //     const items_length = state.items.length;
  //     if (left_or_right === 0) {
  //       new_index = state.home_index - 1;
  //       if (new_index < 0) {
  //         new_index = items_length - 1;
  //       }
  //     } else {
  //       new_index = state.home_index + 1;
  //       if (new_index >= items_length) {
  //         new_index = new_index % items_length;
  //       }
  //     }

  //     return { home_index: new_index };
  //   });
  // };

  onChangeFullScreenState = (fullScreenState) => {
    this.setState({
      fullScreenState,
    });
  };

  responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet_1: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 767, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  create_image_div = (post) => {
    return (
      <Link
        to={`/photography/gallery/slideshow?id=${post.category_id}&index=${post.ref_index}`}
        key={post.id}
      >
        <div className="image-div">
          <img
            src={`${BASE_URL}/cms-system/uploads/${post.url}`}
            alt={post.title}
          />
          <div className="bottom-right">
            {post.title.length < 20
              ? post.title
              : post.title.substring(0, 20) + "..."}
          </div>
        </div>
      </Link>
    );
  };

  imageRenderer({ index, onClick, photo, margin, direction, top, left, key }) {
    // const { history } = this.props;
    // console.log(props)
    let that = this;
    return (
      <div key={photo.id} className="image-div">
        <Photo
          // key={photo.id}
          index={index}
          photo={photo}
          left={left}
          top={top}
          onClick={() => {
            // console.log(photo.title + index);
            // console.log(photo.id);
            // const { history } = this.props;
            // history.replace(
            //   `/photo-art/gallery/slideshow?id=${photo.category_id}&index=${photo.ref_index}`
            // );
            // photo.myhistory.replace(
            //   `/photo-art/gallery/slideshow?id=${photo.category_id}&index=${photo.ref_index}`
            // );
            // console.log("==photo", photo);
            // console.log("==photo", photo.ref_index);
            // photo.that.props.history.push(`/photo-art/gallery?id=${photo.category_id}&pid=${photo.id}`)

            that.setState(
              {
                cat_id: photo.category_id,
                index: photo.ref_index,
                home_index: photo.ref_index,
              },

              () => {
                // console.log("check posts items 3333", photo.that.state);
                that.setGallery(false);
                // setTimeout(() => {
                //   photo.that.setGallery(false);
                // }, 100);
                that.props.history.replace(
                  `/photography/gallery?id=${photo.category_id}&pid=${photo.id}`
                );
              }
            );
          }}
          margin={PHOTO_MARGIN}
          direction={direction}
        />

        {/* <div
          className="gallery-item-div"
          onClick={() => {
            this.setGallery(false);
            this.setState({
              cat_id: item.category_id,
              index: item.ref_index,
            });
          }}
        >
          <img src={item.src} alt="gallery item" />
          <div className="gallery-item-title">{item.title}</div>
        </div> */}

        <div
          className="bottom-right"
          onClick={() => {
            // photo.myhistory.replace(
            //   `/photo-art/gallery/slideshow?id=${photo.category_id}&index=${photo.ref_index}`
            // );
            that.setState(
              {
                cat_id: photo.category_id,
                index: photo.ref_index,
                home_index: photo.ref_index,
              },

              () => {
                console.log("check posts items 3333", that.state);
                that.setGallery(false);
                // setTimeout(() => {
                //   photo.that.setGallery(false);
                // }, 100);
              }
            );
          }}
        >
          {photo.title.length < 20
            ? photo.title
            : photo.title.substring(0, 20) + "..."}
        </div>
      </div>
    );
  }

  get_array_min_index(nums) {
    // var indexMenor = nums.indexOf(Math.min(...nums));

    // console.log(indexMenor);
    return nums.indexOf(Math.min(...nums));
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  child = React.createRef();
  onClick_zoomout = () => {
    this.setGallery(false);
    // const child = React.createRef();
    this.child.current.zoomout();
    // console.log("this", this.child);
  };

  setGallery = (b) => {
    // this.setState({
    //   isGallery: b,
    // });
    const { set_is_gallery } = this.props;
    set_is_gallery(b);
  };

  // move some methods from slidershow to here
  // if 0, move left, else move right
  onClickImage = (left_or_right) => {
    // console.log("onclick===11", this.state);
    // console.log("left or right", left_or_right);
    this.setState(
      (state) => {
        let new_index;
        const items_length = state.items.length;
        if (left_or_right === 0) {
          new_index = state.home_index - 1;
          console.log("onclick===1123", items_length, new_index);
          if (new_index < 0) {
            console.log("onclick===1123456", items_length, new_index);
            new_index = items_length - 1;
          }
        } else {
          new_index = state.home_index + 1;
          // console.log("new_index", new_index);
          if (new_index >= items_length) {
            new_index = new_index % items_length;
          }
        }

        return { home_index: new_index };
      },
      () => {
        console.log("onclick===11233===", this.state);
        setTimeout(() => {
          const { items, home_index } = this.state;
          const { location, history } = this.props;
          const params = new URLSearchParams(location.search);
          const p_id = params.get("pid");
          const cat_id = params.get("id");
          console.log("p_id", p_id);
          console.log("p_id 123 ddd clicked", this.state);
          // console.log(
          //   "onclick===11222===",
          //   `/photo-art/gallery?id=${cat_id}&pid=${items[home_index].id}`
          // );
          history.push(
            `/photography/gallery?id=${cat_id}&pid=${items[home_index].id}`
          );
        }, 1);
      }
    );
  };

  go_back_to_gallery = () => {
    this.get_posts();
    // this.setState(
    //   {
    //     // home_index: null,
    //     isGallery: true,
    //   },
    //   () => {
    //     console.log("state", this.state);
    //   }
    // );
    const { set_is_gallery } = this.props;
    set_is_gallery(true);
  };

  render() {
    const { posts } = this.props;
    const { is_visible } = this.state;
    const {
      items,
      home_index,
      //  change ,
    } = this.state;
    if (posts.length === 0 || items.length === 0) {
      return null;
    }
    if (this.state.home_index === null) {
      return null;
    }

    // console.log("uniq", items, posts);

    // var items = posts.slice(0, 3);
    // console.log("==2", posts);

    const grid_ary = [[], [], [], []];
    const grid_ary_count = [0, 0, 0, 0];
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      item["ref_index"] = i;
      let idx = this.get_array_min_index(grid_ary_count);
      grid_ary_count[idx] =
        item.image_height / item.image_width + grid_ary_count[idx];
      // console.log("item.image_width", item.image_height / item.image_width);
      grid_ary[idx].push(item);
      // item["ref_index"] = i;
      // if (i % 4 === 0) {
      //   grid_ary[0].push(item);
      // }
      // if (i % 4 === 1) {
      //   grid_ary[1].push(item);
      // }
      // if (i % 4 === 2) {
      //   grid_ary[2].push(item);
      // }
      // if (i % 4 === 3) {
      //   grid_ary[3].push(item);
      // }
    }

    const gallery_list = items.map((item) => {
      // src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
      // width: 4,
      // height: 3,
      // title: "my title",
      // id: 3232,
      item.src = `${BASE_URL}/cms-system/uploads/${item.url}`;
      // item.src = `${BASE_URL}/cms-system/uploads/small/${item.url}`;
      item.width = parseInt(item.image_width);
      item.height = parseInt(item.image_height);
      item.myhistory = this.props.history;
      // item.that = this;
      return item;
    });
    // console.log("gallery_list", gallery_list);
    // Tools.sortObjArray(gallery_list, "id", true);
    // console.log("gallery_list ==", gallery_list);

    // const { isGallery } = this.state;
    const { is_gallery } = this.props;

    // console.log("this.state.cat_id", this.state.cat_id);

    const { is_desktop } = this.state;
    let gallery_html = is_desktop ? (
      <Container
        className={`home-container normal ${
          is_gallery ? "display-gallery" : "not-display-gallery"
        }`}
      >
        <Row>
          <Col className="center gallery-header limit-max-width-57">
            {items[home_index].category_name}
          </Col>
        </Row>
        <Row className="gallery-row display-row">
          <Col xl="12" className="container">
            <>
              <span className="vertical-align-helper"></span>
              <div className="slidershow-button">
                <button
                  onClick={() => {
                    console.log("ddd");
                    const { set_first_show_slider_true } = this.props;
                    set_first_show_slider_true();
                    this.props.set_fullscreen_message(true);
                    // var that = this;
                    // setTimeout(() => {
                    //   that.onClick_zoomout();
                    // }, 10);
                    this.onClick_zoomout();
                  }}
                >
                  <span>Slideshow</span>
                  <span className="material-icons">play_arrow</span>
                </button>
              </div>
              <div
                className="slidershow-button back-to-photo-art-index"
                onClick={() => {
                  const { history } = this.props;
                  history.replace(`/photography`);
                }}
              >
                <span className="material-icons">reply</span>
              </div>
            </>
          </Col>
        </Row>
        <Gallery
          photos={gallery_list}
          renderImage={(props) => this.imageRenderer({ ...props })}
          margin={PHOTO_MARGIN}
        />
      </Container>
    ) : (
      <div
        id="mobile-gallery-main-div"
        className={`${is_gallery ? "display-gallery" : "not-display-gallery"}`}
      >
        <div className="margin-top-mobileview"></div>
        <div className="box-title long-title-center">{items[home_index].category_name}</div>
        {/* <div className="box-title mt-n4">photography</div> */}
        <div
          className="slideshow-link"
          // onClick={() => {
          //   console.log("ddd");
          //   const { set_first_show_slider_true } = this.props;
          //   set_first_show_slider_true();
          //   this.props.set_fullscreen_message(true);
          //   this.onClick_zoomout();
          // }}
        >
          <span
            onClick={() => {
              console.log("ddd");
              const { set_first_show_slider_true } = this.props;
              set_first_show_slider_true();
              this.props.set_fullscreen_message(true);
              this.onClick_zoomout();
            }}
          >
            Slideshow
          </span>
        </div>
        {gallery_list.map((item) => {
          return (
            <div
              key={item.ref_index}
              className="gallery-item-div"
              onClick={() => {
                //
                this.setState(
                  {
                    cat_id: item.category_id,
                    index: item.ref_index,
                    home_index: item.ref_index,
                  },
                  () => {
                    this.setGallery(false);
                    this.props.history.replace(
                      `/photography/gallery?id=${item.category_id}&pid=${item.id}`
                    );
                  }
                );
              }}
            >
              <img src={item.src} alt="gallery item" />
              <div className="gallery-item-title">{item.title}</div>
            </div>
          );
        })}
        <div className="scroll-to-top">
          {is_visible && (
            <div onClick={() => this.scrollToTop()}>
              <span className="material-icons">expand_circle_down</span>
            </div>
          )}
        </div>
      </div>
    );

    return (
      <>
        {gallery_html}
        {/* {!isTablet && (
          <BrowserView>
            <Container
              className={`home-container normal ${
                is_gallery ? "display-gallery" : "not-display-gallery"
              }`}
            >
              <Row>
                <Col className="center gallery-header">
                  {items[home_index].category_name}
                </Col>
              </Row>
              <Row className="gallery-row display-row">
                <Col xl="12" className="container">
                  <>
                    <span className="vertical-align-helper"></span>
                    <div className="slidershow-button">
                      <button
                        onClick={() => {
                          console.log("ddd");
                          const { set_first_show_slider_true } = this.props;
                          set_first_show_slider_true();
                          this.onClick_zoomout();
                        }}
                      >
                        <span>Slideshow</span>
                        <span className="material-icons">play_arrow</span>
                      </button>
                    </div>
                    <div
                      className="slidershow-button back-to-photo-art-index"
                      onClick={() => {
                        const { history } = this.props;
                        history.replace(`/photo-art`);
                      }}
                    >
                      <span className="material-icons">reply</span>
                    </div>
                  </>
                </Col>
              </Row>

              <Gallery
                photos={gallery_list}
                renderImage={this.imageRenderer}
                margin={PHOTO_MARGIN}
              />
            </Container>
          </BrowserView>
        )} */}

        {/* <MobileView>
          <div
            id="mobile-gallery-main-div"
            className={`${
              is_gallery ? "display-gallery" : "not-display-gallery"
            }`}
          >
            <div className="margin-top-mobileview"></div>
            <div className="box-title">{items[home_index].category_name}</div>
            <div className="box-title mt-n4">photo art</div>
            <div
              className="slideshow-link"
              onClick={() => {
                console.log("ddd");
                const { set_first_show_slider_true } = this.props;
                set_first_show_slider_true();
                this.onClick_zoomout();
              }}
            >
              Slideshow
            </div>
            {gallery_list.map((item) => {
              return (
                <div
                  key={item.ref_index}
                  className="gallery-item-div"
                  onClick={() => {
                    //
                    this.setState(
                      {
                        cat_id: item.category_id,
                        index: item.ref_index,
                        home_index: item.ref_index,
                      },
                      () => {
                        this.setGallery(false);
                      }
                    );
                  }}
                >
                  <img src={item.src} alt="gallery item" />
                  <div className="gallery-item-title">{item.title}</div>
                </div>
              );
            })}
            <div className="scroll-to-top">
              {is_visible && (
                <div onClick={() => this.scrollToTop()}>
                  <span className="material-icons">expand_circle_down</span>
                </div>
              )}
            </div>
          </div>
        </MobileView> */}

        <NewSlideShow
          isGallery={is_gallery}
          ref={this.child}
          items={items}
          cat_id={this.state.cat_id}
          home_index={this.state.home_index}
          setGallery={this.setGallery}
          posts={posts}
          // get_posts={this.get_posts}
          onClickImage={this.onClickImage}
          go_back_to_gallery={this.go_back_to_gallery}
          set_fullscreen_message={this.props.set_fullscreen_message}
          fullscreen_message={this.props.fullscreen_message}
          mylocation={this.props.location}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.post.posts,
    is_gallery: state.message.is_gallery,
    fullscreen_message: state.message.fullscreen_message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_posts: () => dispatch(actions.get_posts()),
    delete_post: (payload) => dispatch(actions.delete_post(payload)),
    update_post: (payload) => dispatch(actions.update_post(payload)),
    set_first_show_slider_true: () =>
      dispatch(
        actions.set_message({
          name: "first_show_sliders",
          value: true,
        })
      ),
    set_is_gallery: (bool_value) =>
      dispatch(
        actions.set_message({
          name: "is_gallery",
          value: bool_value,
        })
      ),
    set_fullscreen_message: (bool_value) =>
      dispatch(
        actions.set_message({
          name: "fullscreen_message",
          value: bool_value,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyGallery);
