import axios from "axios";
import { put } from "redux-saga/effects";

import { BASE_URL } from "../../consts/const";
import * as actions from "../actions/index";

export function* getWritingCategoriesSaga(action) {
  console.log("i am getWritingCategoriesSaga", action);

  try {
    const result = yield axios.get(
      `${BASE_URL}/cms-system/api/writing_category/read.php`
    );
    console.log("result", result.data.data);
    //   this.setState({
    //     categories: result.data.data,
    //   });
    if (result.data.data) {
      yield put(actions.set_writing_categories(result.data.data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addWritingCategorySaga(action) {
  console.log("i am addWritingCategorySaga", action);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  const { payload } = action;
  const { cat: cat_payload, file } = payload;
  try {
    // upload file first
    const formData = new FormData();
    formData.append("upload", "upload");

    formData.append("file", file);

    console.log("formData", formData);
    let response = yield axios.post(
      `${BASE_URL}/cms-system/api/writing_category/upload.php`,
      formData,
      header
    );
    // upload file end
    const { data, status } = response;
    if (status === 201) {
      const test = data.split("|||||");
      console.log("response----2", test[1]);

      cat_payload.image_url = test[1];
      yield axios.post(
        `${BASE_URL}/cms-system/api/writing_category/create.php`,
        cat_payload,
        header
      );
      yield put(actions.get_writing_categories());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateWritingCategorySaga(action) {
  console.log("i am updateWritingCategorySaga", action);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  const { payload } = action;
  const { cat: cat_payload, file } = payload;

  try {
    let response;

    if (file) {
      console.log("file", file);

      const formData = new FormData();
      formData.append("upload", "upload");

      formData.append("file", file);

      console.log("formData", formData);
      response = yield axios.post(
        `${BASE_URL}/cms-system/api/writing_category/upload.php`,
        formData,
        header
      );
      const { data, status } = response;
      if (status === 201) {
        const test = data.split("|||||");
        console.log("response----", response.status, data, file.size);
        console.log("response----2", test[1]);
        cat_payload.old_url = cat_payload.image_url;
        cat_payload.image_url = test[1];
        // payload.old_url = payload.url;
        // payload.size = file.size;
        // payload.url = test[1];
      }
    }

    yield axios.post(
      `${BASE_URL}/cms-system/api/writing_category/update.php`,
      cat_payload,
      header
    );
    yield put(actions.get_writing_categories());
  } catch (error) {
    console.log(error);
  }
}

export function* deleteWritingCategorySaga(action) {
  console.log("i am deleteWritingCategorySaga", action);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  const { payload } = action;
  try {
    yield axios.post(
      `${BASE_URL}/cms-system/api/writing_category/delete.php`,
      payload,
      header
    );
    yield put(actions.get_writing_categories());
  } catch (error) {
    console.log(error);
  }
}

export function* updateWritingCategoryOrderSaga(action) {
  console.log("i am updateWritingCategoryOrderSaga", action);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  const { payload } = action;
  try {
    yield axios.post(
      `${BASE_URL}/cms-system/api/writing_category/update_order.php`,
      payload,
      header
    );
    yield put(actions.get_writing_categories());
  } catch (error) {
    console.log(error);
  }
}
