import React from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
// import { Col, Button, Form, FormGroup, Label, Input } from "reactstrap";

import "./ShowImages.css";
import {
  Button,
  FormGroup,
  //   Label,
  Input,
  //   Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { BASE_URL } from "../../consts/const";
import * as actions from "../../store/actions/index";

class ShowImages extends React.Component {
  state = {
    delete_post: null,
    modal: false,
  };

  componentDidMount() {
    console.log(this.props);

    const { get_posts } = this.props;
    get_posts();
  }

  deleteImage = async (id, url) => {
    console.log("id", id);

    const payload = {
      id,
      url,
    };
    const { delete_post } = this.props;
    delete_post(payload);
  };

  onChangeDeleteStatusSubmit = (post) => {
    console.log("post", post);
    post.delete = post.delete === "1" ? 0 : 1;
    const { update_post } = this.props;
    update_post(post);
  };

  onClickDelete = (post) => {
    this.setState({
      delete_post: post,
      modal: true,
    });
  };

  onClickDeleteConfirm = () => {
    const { delete_post: post } = this.state;
    // delete_post.delete = delete_post.delete === "1" ? 0 : 1;
    // const { update_post } = this.props;
    // update_post(delete_post);
    const payload = {
      id: post.id,
      url: post.url,
    };
    const { delete_post } = this.props;
    delete_post(payload);
    this.setState({
      delete_post: null,
      modal: false,
    });
  };

  onClickDeleteCancel = () => {
    this.setState({
      delete_post: null,
      modal: false,
    });
  };

  render() {
    const { posts } = this.props;

    return (
      <div className="admin-images-list-main">
        <div className="admin-header">Image Trash</div>

        {/* <div className="float-right">
          <Link to="/admin/post/add">Add image</Link>
        </div> */}

        <Row className="mt-5" id="admin-list-images-row">
          <Col xl="2" className="admin-list-image-col"></Col>
          <Col xl="2" className="admin-list-image-col">
            Image Name
          </Col>
          <Col xl="1" className="admin-list-image-col">
            Location
          </Col>
          <Col xl="1" className="admin-list-image-col">
            Category
          </Col>
          <Col xl="1" className="admin-list-image-col col-header">
            Image Size
          </Col>
          <Col xl="1" className="admin-list-image-col">
            Modified
          </Col>
          <Col xl="1" className="admin-list-image-col">
            Home
          </Col>
          <Col xl="1" className="admin-list-image-col">
            Gallery
          </Col>
          <Col xl="1" className="admin-list-image-col"></Col>
          <Col xl="1" className="admin-list-image-col"></Col>

          <Col xl="12">
            <hr />
          </Col>
          {posts
            .filter((post) => {
              return post.delete === "1";
            })
            .map((post) => {
              return (
                <React.Fragment key={post.url}>
                  <Col xl="2" className="admin-list-image-col">
                    <img
                      src={`${BASE_URL}/cms-system/uploads/${post.url}`}
                      width={"130px"}
                      alt={post.title}
                    />
                  </Col>
                  <Col xl="2" className="admin-list-image-col">
                    {post.title}
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    {post.location_name}
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    {post.category_name}
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    {post.size}
                  </Col>
                  <Col xl="1" className="admin-list-image-col no-wrop">
                    {post.modified_at.split(" ")[0]}
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <FormGroup check>
                      <Input
                        type="checkbox"
                        checked={post.home !== "0"}
                        readOnly
                        // onChange={() => this.onChangeHomeSubmit(post)}
                      />{" "}
                    </FormGroup>
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <FormGroup check>
                      <Input
                        type="checkbox"
                        checked={post.gallery !== "0"}
                        readOnly
                        // onChange={() => this.onChangeGallerySubmit(post)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <Button
                      color="link"
                      className="link-button"
                      onClick={() => this.onChangeDeleteStatusSubmit(post)}
                    >
                      Restore
                    </Button>
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <Button
                      color="link"
                      className="link-button"
                      //   onClick={() => this.deleteImage(post.id, post.url)}
                      onClick={() => this.onClickDelete(post)}
                    >
                      Delete
                    </Button>
                  </Col>
                </React.Fragment>
              );
            })}
        </Row>
        <Modal isOpen={this.state.modal}>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalBody>Are you sure?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onClickDeleteConfirm}>
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.onClickDeleteCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {posts.length === 0 && <div>No image found</div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.post.posts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_posts: () => dispatch(actions.get_posts()),
    delete_post: (payload) => dispatch(actions.delete_post(payload)),
    update_post: (payload) => dispatch(actions.update_post(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowImages);
