import React from "react";
import { Row, Col } from "reactstrap";
import "./PhotoArtBio.css";

class PhotoArtBio extends React.Component {
  render() {
    return (
      <>
        <div className="photo-art-bio-main-div container">
          <Row>
            <Col xl="7">
              <p>Why do I take pictures?</p>
              <p>
                In almost everything I do, I can’t help but be driven by a
                search for beauty.
              </p>
              <p>
                This perception of beauty moves me, whether in shapes of tree
                branches against clouds in the sky or incredible mathematical
                constructs designed from abstract building blocks of logic.
              </p>
              <p>
                When it comes to the natural world around us, from birds
                standing by a lakeshore to a squirrel doing ‘yoga’ in the sun,
                the world is worth noticing.
              </p>
              <p>
                In my pictures I try to capture what I feel when something
                catches my eye. Something in the landscape, light, composition
                touches me. I take the picture and process it because it speaks
                to me. I hope through these galleries I can share some those
                same feelings with others.
              </p>
              <p>
                This is how I see the world today. Tomorrow my vision of beauty
                and meaning will likely change. I am trying to enjoy the journey
                while it lasts.
              </p>
            </Col>
            <Col xl="5"></Col>
          </Row>
        </div>
      </>
    );
  }
}

export default PhotoArtBio;
