import * as actionTypes from "./actionTypes";

export const get_posts = () => {
  console.log("get_posts action");
  return {
    type: actionTypes.GET_POSTS,
  };
};

export const set_posts = (payload) => {
  // console.log("set_posts action", payload);
  return {
    type: actionTypes.SET_POSTS,
    payload,
  };
};

export const delete_post = (payload) => {
  // console.log("delete_post action", payload);
  // payload : id , url
  return {
    type: actionTypes.DELETE_POST,
    payload,
  };
};

export const add_post = (payload) => {
  // console.log("add_post action", payload);
  // payload
  return {
    type: actionTypes.ADD_POST,
    payload,
  };
};

export const update_post = (payload, file) => {
  // console.log("update_post action", payload, file);
  // payload
  return {
    type: actionTypes.UPDATE_POST,
    payload,
    file,
  };
};

export const reorder_posts = (payload) => {
  // console.log("reorder_posts action", payload);
  // payload
  return {
    type: actionTypes.REORDER_POSTS,
    payload,
  };
};
