import axios from "axios";
import { put } from "redux-saga/effects";

import { BASE_URL } from "../../consts/const";
import * as actions from "../actions/index";

export function* getCategoriesSaga(action) {
  console.log("i am getCategoriesSaga", action);

  try {
    const result = yield axios.get(
      `${BASE_URL}/cms-system/api/category/read.php`
    );
    console.log("result", result.data.data);
    //   this.setState({
    //     categories: result.data.data,
    //   });
    yield put(actions.set_categories(result.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* addCategorySaga(action) {
  console.log("i am addCategorySaga", action);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  const { payload } = action;
  try {
    yield axios.post(
      `${BASE_URL}/cms-system/api/category/create.php`,
      payload,
      header
    );
    yield put(actions.get_categories());
  } catch (error) {
    console.log(error);
  }
}

export function* updateCategorySaga(action) {
  console.log("i am updateCategorySaga", action);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  const { payload } = action;
  try {
    yield axios.post(
      `${BASE_URL}/cms-system/api/category/update.php`,
      payload,
      header
    );
    yield put(actions.get_categories());
  } catch (error) {
    console.log(error);
  }
}


export function* deleteCategorySaga(action) {
  console.log("i am deleteCategorySaga", action);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  const { payload } = action;
  try {
    yield axios.post(
      `${BASE_URL}/cms-system/api/category/delete.php`,
      payload,
      header
    );
    yield put(actions.get_categories());
  } catch (error) {
    console.log(error);
  }
}

export function* updateCategoryOrderSaga(action) {
  console.log("i am updateCategoryOrderSaga", action);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  const { payload } = action;
  try {
    yield axios.post(
      `${BASE_URL}/cms-system/api/category/update_order.php`,
      payload,
      header
    );
    yield put(actions.get_categories());
  } catch (error) {
    console.log(error);
  }
}
