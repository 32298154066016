import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  // Label,
  // Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import * as actions from "../../../store/actions/index";

import "./Published.css";

class Published extends React.Component {
  state = {
    modal: false,
  };

  componentDidMount() {
    const { get_writings } = this.props;
    get_writings();
  }

  onClickEdit = (writing) => {
    // console.log("edit", this.props);
    const { push } = this.props.history;
    push({
      pathname: "/admin/published/update",
      writing,
    });
  };

  onClickDelete = (writing) => {
    this.setState({
      delete_writing: writing,
      modal: true,
    });
  };

  onClickDeleteConfirm = () => {
    const { delete_writing } = this.state;
    delete_writing.delete = delete_writing.delete === "1" ? 0 : 1;
    const { update_writing } = this.props;
    update_writing(delete_writing);
    this.setState({
      delete_writing: null,
      modal: false,
    });
  };

  onClickDeleteCancel = () => {
    this.setState({
      delete_writing: null,
      modal: false,
    });
  };

  render() {
    const { writings } = this.props;
    const writings_1 = writings.filter((item) => {
      return item.delete !== "1";
    });
    console.log("writings", writings);
    return (
      <>
        <div className="admin-images-list-main">
          <div className="admin-header">
            Articles{" "}
            <span className="total-detail">
              {/* Total: {total} / published: {published_total} */}
            </span>
          </div>
          <Row className="mt-5" id="admin-list-images-row">
            <Col xl="2" className="admin-list-image-col col-header">
              Title
            </Col>
            {/* <Col xl="2" className="admin-list-image-col col-header">
              Content
            </Col> */}
            <Col xl="2" className="admin-list-image-col col-header">
              Category
            </Col>
            <Col xl="2" className="admin-list-image-col col-header">
              Publish at
            </Col>
            <Col xl="1" className="admin-list-image-col col-header">
              View page
            </Col>
            <Col xl="1" className="admin-list-image-col col-header"></Col>
            <Col xl="1" className="admin-list-image-col col-header"></Col>
          </Row>
          <Row id="admin-list-images-row">
            <Col xl="12">
              <hr />
            </Col>
          </Row>

          {writings_1.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <Row id="admin-list-images-row">
                  <Col xl="2" className="admin-list-image-col">
                    {item.title}
                  </Col>
                  {/* <Col xl="2" className="admin-list-image-col">
                    {item.body.length > 65
                      ? item.body.substring(0, 62) + "..."
                      : item.body}
                  </Col> */}
                  <Col xl="2" className="admin-list-image-col">
                    {item.category_name}
                  </Col>
                  <Col xl="2" className="admin-list-image-col">
                    {item.publish_at.split(" ")[0]}
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/writings/${item.category_id}/${item.id}`}
                    >
                      view
                    </Link>
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <Button
                      color="link"
                      className="link-button"
                      onClick={() => this.onClickEdit(item)}
                    >
                      Edit
                    </Button>
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <Button
                      color="link"
                      className="link-button"
                      // onClick={() => this.onChangeDeleteSubmit(post)}
                      onClick={() => this.onClickDelete(item)}
                    >
                      Unpublish
                    </Button>
                  </Col>
                </Row>
              </React.Fragment>
            );
          })}

          <Modal isOpen={this.state.modal}>
            <ModalHeader>Confirm Unpublish</ModalHeader>
            <ModalBody>Are you sure?</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.onClickDeleteConfirm}>
                Yes
              </Button>{" "}
              <Button color="secondary" onClick={this.onClickDeleteCancel}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <div className="add-icon-div container">
            <Link to="/admin/published/add">
              <span className="material-icons">add_circle_outline</span>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    writings: state.writing.writings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_writings: () => dispatch(actions.get_writings()),
    update_writing: (payload) => dispatch(actions.update_writing(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Published);
