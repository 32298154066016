import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import axios from "axios";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  // Badge,
} from "reactstrap";

import "./CreatePost.css";
// import { BASE_URL } from "../../consts/const";
import * as actions from "../../store/actions/index";

const CreatePost = (props) => {
  const [dimensions, setDimensions] = useState({ height: 1, width: 1 });
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);

  const [title, setTitleValue] = useState("");
  const onChangeTitleHandler = (event) => {
    setTitleValue(event.target.value);
  };

  const [body, setBodyValue] = useState("");
  const onChangeBodyHandler = (event) => {
    setBodyValue(event.target.value);
  };

  const [tags, setTagsValue] = useState("");
  const onChangeTagsHandler = (event) => {
    setTagsValue(event.target.value);
  };

  const [category, setCategoryValue] = useState("1");
  const onChangeCategoryHandler = (event) => {
    setCategoryValue(event.target.value);
  };

  const [location, setLocationValue] = useState("1");
  const onChangeLocationHandler = (event) => {
    setLocationValue(event.target.value);
  };

  const filePickerRef = useRef();
  const {
    get_locations,
    get_categories,
    init_admin_add_post_status,
    // get_posts,
  } = props;
  useEffect(() => {
    // get_posts();
    get_categories();
    get_locations();
    init_admin_add_post_status();

    //

    if (!file) {
      return;
    }
    console.log("file", file.size);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
  }, [
    file,
    get_categories,
    get_locations,
    init_admin_add_post_status,
    // get_posts,
  ]);

  const pickedHandler = (event) => {
    // console.log(event.target);
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    console.log("pickedFile, fileIsValid", pickedFile, fileIsValid);
    // props.onInput(props.id, pickedFile, fileIsValid);
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  const submitHandler = async () => {
    const { add_post } = props;
    // add_post({ name: "name" });

    const payload = {
      file,
      post: {
        title,
        body,
        author: "author 2",
        category_id: category,
        location_id: location,
        size: file.size,
        image_height: dimensions.height,
        image_width: dimensions.width,
        tags,
        // url,
      },
    };

    add_post(payload);
  };

  const onload_image = ({ target: img }) => {
    setDimensions({
      height: img.offsetHeight,
      width: img.offsetWidth,
    });
    console.log("file==", img.offsetHeight, img.offsetWidth);
  };

  const {
    locations,
    categories,
    // posts,
    admin_add_post_success,
    admin_add_post_failed,
  } = props;
  console.log("categories", categories);
  console.log("locations", locations);
  if (categories.length === 0 || locations.lenth === 0) {
    return null;
  }

  // if (posts && posts.length !== 0) {
  //   // console.log("for tags", posts);
  //   // const existing_tags = [];
  //   const test = posts.map((post) => post.tags.split(","));
  //   // .map((tag) => {
  //   //   existing_tags.push(tag);
  //   // });

  //   const existing_tags = []
  //     .concat(...test)
  //     .map((tag) => tag.trim())
  //     .filter((tag) => tag !== "");
  //   console.log("existing_tags", existing_tags);
  // }

  return (
    <>
      <div className="admin-images-list-main">
        <div className="admin-header mb-5">Add a new Image</div>
        <Form>
          <FormGroup row>
            <Label for="post-title" sm={2}>
              Title
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="post-title"
                id="post-title"
                placeholder="Enter title"
                value={title}
                onChange={onChangeTitleHandler}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="post-body" sm={2}>
              Description
            </Label>
            <Col sm={10}>
              <Input
                // type="textarea"
                type="text"
                name="post-body"
                id="post-body"
                placeholder="Enter description"
                value={body}
                onChange={onChangeBodyHandler}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="post-body" sm={2}>
              Tags
            </Label>
            <Col sm={10}>
              <Input
                type="text"
                name="post-tags"
                id="post-tags"
                placeholder="Use comma to separate each tag"
                value={tags}
                onChange={onChangeTagsHandler}
              />
              {/* <Button color="primary" outline>
                Edit Tags <Badge color="secondary"></Badge>
              </Button> */}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="exampleSelect" sm={2}>
              Gallery
            </Label>
            <Col sm={10}>
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                value={category}
                onChange={onChangeCategoryHandler}
              >
                {categories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="exampleSelect1" sm={2}>
              Location
            </Label>
            <Col sm={10}>
              <Input
                type="select"
                name="select"
                id="exampleSelect1"
                value={location}
                onChange={onChangeLocationHandler}
              >
                {locations.map((location) => {
                  return (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </FormGroup>

          <FormGroup>
            {/* <Col sm={6}> */}
            <input
              ref={filePickerRef}
              type="file"
              accept="image/gif, image/jpeg, image/png"
              style={{ display: "none" }}
              onChange={pickedHandler}
            />
            <div className="mb-2">
              {previewUrl && (
                <img
                  src={previewUrl}
                  alt="Preview"
                  width="500px"
                  onLoad={onload_image}
                />
              )}
              {!previewUrl && (
                <p onClick={pickImageHandler}>Please pick an image.</p>
              )}
            </div>
            <Button
              outline
              color="primary"
              type="button"
              onClick={pickImageHandler}
            >
              PICK IMAGE
            </Button>
          </FormGroup>
          <div className="">
            <Button
              onClick={submitHandler}
              color="primary"
              disabled={
                !file || title.trim().length < 2 || body.trim().length < 2
              }
            >
              SUBMIT
            </Button>
          </div>
        </Form>
        {admin_add_post_success && (
          <div className="mt-4">
            <Alert color="success">
              Image created! <Link to="/admin/post">Back</Link>
            </Alert>
          </div>
        )}
        {admin_add_post_failed && (
          <div className="mt-4">
            <Alert color="warning">
              {admin_add_post_failed} <Link to="/admin/post">Back</Link>
            </Alert>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.category.categories,
    locations: state.location.locations,
    posts: state.post.posts,
    admin_add_post_success: state.message.admin_add_post_success,
    admin_add_post_failed: state.message.admin_add_post_failed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_categories: () => dispatch(actions.get_categories()),
    get_locations: () => dispatch(actions.get_locations()),
    get_posts: () => dispatch(actions.get_posts()),
    add_post: (payload) => dispatch(actions.add_post(payload)),
    init_admin_add_post_status: () =>
      dispatch(
        actions.set_message({
          name: "admin_add_post_success",
          value: false,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost);
