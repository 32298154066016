import * as actionTypes from "./actionTypes";

export const set_message = (payload) => {
  // payload : name : value
  console.log("set_message action", payload);
  return {
    type: actionTypes.SET_MESSAGE,
    payload,
  };
};
