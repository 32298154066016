import { takeEvery, all } from "redux-saga/effects";

import * as actionTypes from "../actions/actionTypes";
import {
  addCategorySaga,
  deleteCategorySaga,
  getCategoriesSaga,
  updateCategoryOrderSaga,
  updateCategorySaga,
} from "./category";
import {
  addLocationSaga,
  deleteLocationSaga,
  getLocationsSaga,
  updateLocationSaga,
} from "./location";
import {
  addPostSaga,
  deletePostSaga,
  getpostsSaga,
  reorderPostsSaga,
  updatePostSaga,
} from "./post";

import {
  getCodeSaga,
  isAuthSaga,
  loginSaga,
  logoutSaga,
  resetPasswordSaga,
} from "./user";
import {
  addWritingSaga,
  checkWritingFilesSaga,
  deleteImageSaga,
  deleteWritingSaga,
  getWritingsSaga,
  updateWritingSaga,
  uploadImagesSaga,
} from "./writing";
import {
  addWritingCategorySaga,
  deleteWritingCategorySaga,
  getWritingCategoriesSaga,
  updateWritingCategoryOrderSaga,
  updateWritingCategorySaga,
} from "./writing_category";

export function* watchUser() {
  yield all([
    takeEvery(actionTypes.LOGOUT, logoutSaga),
    takeEvery(actionTypes.IS_AUTH, isAuthSaga),
    takeEvery(actionTypes.LOGIN, loginSaga),
    takeEvery(actionTypes.GET_CODE, getCodeSaga),
    takeEvery(actionTypes.RESET_PASSWORD, resetPasswordSaga),
  ]);
}

export function* watchCategory() {
  yield all([
    takeEvery(actionTypes.GET_CATEGORIES, getCategoriesSaga),
    takeEvery(actionTypes.ADD_CATEGORY, addCategorySaga),
    takeEvery(actionTypes.UPDATE_CATEGORY, updateCategorySaga),
    takeEvery(actionTypes.DELETE_CATEGORY, deleteCategorySaga),
    takeEvery(actionTypes.UPDATE_CATEGORY_ORDER, updateCategoryOrderSaga),
  ]);
}

export function* watchLocation() {
  yield all([
    takeEvery(actionTypes.GET_LOCATIONS, getLocationsSaga),
    takeEvery(actionTypes.ADD_LOCATION, addLocationSaga),
    takeEvery(actionTypes.UPDATE_LOCATION, updateLocationSaga),
    takeEvery(actionTypes.DELETE_LOCATION, deleteLocationSaga),
  ]);
}

export function* watchPost() {
  yield all([
    takeEvery(actionTypes.GET_POSTS, getpostsSaga),
    takeEvery(actionTypes.DELETE_POST, deletePostSaga),
    takeEvery(actionTypes.ADD_POST, addPostSaga),
    takeEvery(actionTypes.UPDATE_POST, updatePostSaga),
    takeEvery(actionTypes.REORDER_POSTS, reorderPostsSaga),
  ]);
}

// writing secion
export function* watchWritingCategory() {
  yield all([
    takeEvery(actionTypes.GET_WRITING_CATEGORIES, getWritingCategoriesSaga),
    takeEvery(actionTypes.ADD_WRITING_CATEGORY, addWritingCategorySaga),
    takeEvery(actionTypes.UPDATE_WRITING_CATEGORY, updateWritingCategorySaga),
    takeEvery(actionTypes.DELETE_WRITING_CATEGORY, deleteWritingCategorySaga),
    takeEvery(
      actionTypes.UPDATE_WRITING_CATEGORY_ORDER,
      updateWritingCategoryOrderSaga
    ),
  ]);
}

// writing
export function* watchWriting() {
  yield all([
    takeEvery(actionTypes.GET_WRITINGS, getWritingsSaga),
    takeEvery(actionTypes.ADD_WRITING, addWritingSaga),
    takeEvery(actionTypes.UPDATE_WRITING, updateWritingSaga),
    takeEvery(actionTypes.DELETE_WRITING, deleteWritingSaga),
    takeEvery(actionTypes.UPLOAD_IMAGE, uploadImagesSaga),
    takeEvery(actionTypes.DELETE_IMAGE, deleteImageSaga),
    takeEvery(actionTypes.CHECK_WRITING_FILES, checkWritingFilesSaga),
  ]);
}
