import axios from "axios";
import { put } from "redux-saga/effects";

import { BASE_URL } from "../../consts/const";
import * as actions from "../actions/index";

export function* getLocationsSaga(action) {
  console.log("i am getLocationsSaga", action);

  try {
    const result = yield axios.get(
      `${BASE_URL}/cms-system/api/location/read.php`
    );
    console.log("result", result.data.data);
    //   this.setState({
    //     locations: result.data.data,
    //   });
    yield put(actions.set_locations(result.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* addLocationSaga(action) {
  console.log("i am addLocationSaga", action);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  const { payload } = action;
  try {
    yield axios.post(
      `${BASE_URL}/cms-system/api/location/create.php`,
      payload,
      header
    );
    yield put(actions.get_locations());
  } catch (error) {
    console.log(error);
  }
}

export function* updateLocationSaga(action) {
  console.log("i am updateLocationSaga", action);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  const { payload } = action;
  try {
    yield axios.post(
      `${BASE_URL}/cms-system/api/location/update.php`,
      payload,
      header
    );
    yield put(actions.get_locations());
  } catch (error) {
    console.log(error);
  }
}

export function* deleteLocationSaga(action) {
  console.log("i am deleteLocationSaga", action);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  const { payload } = action;
  try {
    yield axios.post(
      `${BASE_URL}/cms-system/api/location/delete.php`,
      payload,
      header
    );
    yield put(actions.get_locations());
  } catch (error) {
    console.log(error);
  }
}
