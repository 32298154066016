import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Input,
  // Container,
  Button,
  Modal,
  Spinner,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Photo } from "react-photo-gallery";
import Gallery from "react-photo-gallery";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import * as actions from "../../store/actions/index";

import "./Sort.css";
import { BASE_URL } from "../../consts/const";
const PHOTO_MARGIN = 5;

class Sort extends Component {
  state = {
    cat_posts: [],
    is_modal_open: false,
  };
  componentDidMount() {
    const { get_posts, posts, get_categories } = this.props;
    get_posts();
    get_categories();
    console.log("sorted", posts);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.admin_updating_image_order !==
      this.props.admin_updating_image_order
    ) {
      // const { posts } = nextProps;
      // const { location } = this.props;
      // const params = new URLSearchParams(location.search);
      // const cat_id = params.get("id");
      // const items = posts.filter((post) => {
      //   return post.category_id === cat_id && post.delete !== "1";
      // });
      // const home_post_index = items.findIndex((post) => {
      //   return post.category_id === cat_id && post.gallery === "1";
      // });
      // console.log("okok", home_post_index);
      // this.setState({
      //   items,
      // });
      const { admin_updating_image_order } = nextProps;
      if (admin_updating_image_order === "loading") {
        this.setState({
          modal_componet: (
            <div>
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
              <Spinner type="grow" color="warning" />
              <Spinner type="grow" color="info" />
              <Spinner type="grow" color="light" />
              <Spinner type="grow" color="dark" />
            </div>
          ),
        });
      }
      if (admin_updating_image_order === "success") {
        this.setState({
          modal_componet: <div>Updated successfully</div>,
        });
      }
      if (admin_updating_image_order === "failure") {
        this.setState({
          modal_componet: (
            <div>Something went wrong, please try again later</div>
          ),
        });
      }
    }
    return true;
  }

  onChangeCategory = (e) => {
    console.log(e.target.value);
    const { value } = e.target;

    this.setState({ category: value }, () => {
      console.log("sorted select category", this.state);
    });

    /// ====
    const { posts } = this.props;
    // const { category } = this.state;
    // console.log("sorted my_state", my_state);

    let posts_for_cat = [];
    let gallery_list = [];

    posts_for_cat = this.get_cat_posts(posts, value);

    console.log("sorted_posts_for catagory", posts_for_cat);
    gallery_list = posts_for_cat.map((item) => {
      item.src = `${BASE_URL}/cms-system/uploads/${item.url}`;

      item.width = parseInt(item.image_width);
      item.height = parseInt(item.image_height);
      item.myhistory = this.props.history;
      item.that = this;
      return item;
    });
    this.setState({
      cat_posts: [...gallery_list],
    });
  };

  // imageRenderer({ index, onClick, photo, margin, direction, top, left, key }) {
  //   return (
  //     <div key={photo.id} className="image-div">
  //       <Photo
  //         index={index}
  //         photo={photo}
  //         left={left}
  //         top={top}
  //         margin={PHOTO_MARGIN}
  //         direction={direction}
  //       />

  //       <div className="bottom-right">
  //         {photo.title.length < 20
  //           ? photo.title
  //           : photo.title.substring(0, 20) + "..."}
  //       </div>
  //     </div>
  //   );
  // }

  // SortablePhoto = SortableElement(
  //   ({ index, onClick, photo, margin, direction, top, left, key }) => {
  //     return (
  //       <div key={photo.id} className="image-div">
  //         <Photo
  //           index={index}
  //           photo={photo}
  //           left={left}
  //           top={top}
  //           margin={PHOTO_MARGIN}
  //           direction={direction}
  //         />

  //         <div className="bottom-right">
  //           {photo.title.length < 20
  //             ? photo.title
  //             : photo.title.substring(0, 20) + "..."}
  //         </div>
  //       </div>
  //     );
  //   }
  // );

  // SortableGallery = SortableContainer(({ items }) => (
  //   <Gallery
  //     margin={PHOTO_MARGIN}
  //     photos={items}
  //     renderImage={(props) => <this.SortablePhoto {...props} />}
  //   />
  // ));

  get_cat_posts = (posts, cat_id) => {
    console.log("cat_id", cat_id);
    return posts.filter((post) => {
      return (
        post.category_id === cat_id &&
        post.delete !== "1" &&
        post.location_id !== "1" &&
        post.category_id !== "1"
      );
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    // setItems(arrayMove(this.items, oldIndex, newIndex));
    this.setState(
      {
        cat_posts: arrayMove(this.state.cat_posts, oldIndex, newIndex),
      },
      () => {
        console.log("cat_posts", this.state.cat_posts);
      }
    );
  };

  // SortablePhoto = SortableElement(
  //   ({ index, onClick, photo, margin, direction, top, left, key }) => {
  //     return (
  //       <Photo
  //         key={key}
  //         index={1}
  //         photo={photo}
  //         left={left}
  //         top={top}
  //         onClick={() => {
  //           console.log(photo.title + index);
  //           console.log(photo.id);
  //         }}
  //         margin={PHOTO_MARGIN}
  //         direction={direction}
  //       />
  //     );
  //   }
  // );

  // SortableGallery = SortableContainer(({ items }) => (
  //   <Gallery
  //     margin={PHOTO_MARGIN}
  //     photos={items}
  //     renderImage={(props) => <this.SortablePhoto {...props} />}
  //   />
  // ));

  SortablePhoto = (index) =>
    SortableElement((item) => {
      // console.log("item...", item.photo.title);
      return (
        <div className="image-div">
          <Photo {...item} index={index} />
          <div className="bottom-right">
            {item.photo.title.length < 20
              ? item.photo.title
              : item.photo.title.substring(0, 20) + "..."}
          </div>
        </div>
      );
    });

  SortableGallery = SortableContainer(({ items }) => (
    <Gallery
      margin={PHOTO_MARGIN}
      photos={items}
      renderImage={(props) => {
        const IndexedSortablePhoto = this.SortablePhoto(props.index);

        return <IndexedSortablePhoto {...props} />;
      }}
    />
  ));

  save_order = () => {
    const { cat_posts } = this.state;
    const { reorder_post } = this.props;
    const payload = cat_posts.map((post, index) => {
      return {
        post_id: post.id,
        order_number: post.order_number,
        new_order_number: index,
      };
    });
    console.log("payload_sorted", payload);
    reorder_post(payload);
    this.setState({ is_modal_open: true });
  };

  render() {
    const {
      // my_state,
      categories,
      //  posts
    } = this.props;
    const { category, cat_posts, is_modal_open, modal_componet } = this.state;

    // const { admin_updating_image_order } = this.props;

    return (
      <>
        <div className="sort-image-main-div">
          {/* <Gallery
          photos={posts_for_cat}
          renderImage={this.imageRenderer}
          margin={PHOTO_MARGIN}
        /> */}
          <div className="sort-image-title-div">
            <div className="row">
              <div className="col-sm-2">
                <span
                  className="material-icons"
                  onClick={() => {
                    const { history } = this.props;
                    console.log(history);
                    history.replace(`/admin`);
                  }}
                >
                  reply
                </span>
              </div>
              <div className="col-sm-8">Drag to rearrange images order</div>
            </div>
          </div>
          <Input
            type="select"
            name="select"
            id="exampleSelect"
            value={category}
            // value={cat_id}
            onChange={this.onChangeCategory}
            // onChange={() => { history.push(`/photo-art/gallery?id=${post.category_id}`) }}
          >
            <option value={""}>Galleries</option>
            {categories
              .filter((item) => item.id !== "1")
              .map((item) => {
                console.log("sorted", item);
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </Input>
          <br />
          <this.SortableGallery
            items={cat_posts}
            onSortEnd={this.onSortEnd}
            axis={"xy"}
          />
        </div>

        {cat_posts.length > 1 && (
          <div className="sorted-footer " id="button-div">
            <Button
              color="primary"
              onClick={() => {
                this.save_order();
                // const { history } = this.props;
                // console.log(history);
                // history.replace(`/admin`);
              }}
            >
              Save
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                const { history } = this.props;
                console.log(history);
                history.replace(`/admin`);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
        <Modal isOpen={is_modal_open} className="admin-reorder-modal">
          <ModalBody>{modal_componet}</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.setState({ is_modal_open: false })}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.post.posts,
    categories: state.category.categories,
    admin_updating_image_order: state.message.admin_updating_image_order,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_posts: () => dispatch(actions.get_posts()),
    get_categories: () => dispatch(actions.get_categories()),
    reorder_post: (payload) => dispatch(actions.reorder_posts(payload)),
    // delete_post: (payload) => dispatch(actions.delete_post(payload)),
    // update_post: (payload) => dispatch(actions.update_post(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sort);
