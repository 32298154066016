import React from "react";
import { connect } from "react-redux";
import // BrowserView,
// MobileView,
// isTablet,
// isBrowser,
// isMobile,
"react-device-detect";
// import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
// import {
//   Card,
//   CardBody,
//   Button,
//   CardTitle,
//   CardText,
//   CardImg,
// } from "reactstrap";
// import { Button } from "reactstrap";

import "./Home.css";
import "./components/mobile/Home.css";
import "../../components/css/media/home.css";
import "../../components/css/ef/home.css";

import * as actions from "../../store/actions/index";
import { BASE_URL } from "../../consts/const";
import { PHOTO_ART_ROOT_URL } from "../../consts/const";

// import bio_image_01 from "../../images/bio-image-01.png";
import signature from "../../images/EnikoFodor1.png";
import profile_image from "../../images/EnikoProfile-edit-3-Frame-Right.jpg";
import profile_mobile_image from "../../images/EnikoProfile-edit-3-Frame-Top.jpg";

// import name_logo from "../../images/name_log.png";

// import Menu from "./components/Menu";
// import Bio from "./components/HomeBio";
// import BioMobile from "../home/components/mobile/HomeBioMobile";
// import Foot from "./components/Foot";

import Tools from "../../utils/tools";

class Home extends React.Component {
  state = {
    // posts: [],
    index: 0,
    active: true,
    is_visible: false,
    isModalOpen: false,
  };

  componentDidMount() {
    // go to top start
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
    // go to top end

    console.log(this.props);
    // console.log("===444");
    const { get_posts } = this.props;
    get_posts();

    //   = setInterval(() => {
    //   console.log('This will run every second!');
    // }, 1000);

    const intervalId = setInterval(() => {
      // console.log("====333");

      // console.log("ddd");
      this.setState(
        (prevState) => {
          return {
            active: true,
            index: prevState.index + 1,
          };
        },
        () => {
          // console.log("=====", this.state);
          setTimeout(() => {
            this.setState({ active: false });
          }, 5000);
          const { posts } = this.props;
          if (posts) {
            const posts_filtered = posts.filter((post) => {
              return post.delete !== "1";
            });
            const posts_num = posts_filtered.length;
            const index_n = (this.state.index + 1) % posts_num;
            const img = new Image();
            img.src = `${BASE_URL}/cms-system/uploads/${posts_filtered[index_n].url}`;
          }
        }
      );
    }, 5000);
    this.setState({ intervalId: intervalId });

    console.log("window.innerWidth", Tools.GetWindowWidth());
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    const { active, index } = this.state;
    const { is_visible } = this.state;
    const { posts } = this.props;
    const posts_filtered = posts.filter((post) => {
      return post.delete !== "1";
    });
    // .filter((post) => {
    //   return post.home === "1";
    // });
    if (!posts_filtered || posts_filtered.length === 0) return null;

    const posts_num = posts_filtered.length;
    const index_n = index % posts_num;
    let about_me_content = (
      <>
        <p>
          I was born behind the Iron Curtain in Transylvania, a land associated
          in popular culture with the legend of Dracula, although the historical
          Vlad Dracul had little to do with Transylvania. A place of rich
          natural beauty with much of the last remaining old growth forests in
          Europe. A 100,000 square kilometer area that was bartered to Romania
          during the post-war land grab. A place where Ceaușescu’s government
          implemented a systematic displacement of the native population.
          Overactive secret police, uneven playing field for jobs or university
          spots, suppression and replacement of language were among the daily
          realities for people across Transylvania during the communist regime.
        </p>
        <p>
          I studied at Universitatea Babeș-Bolyai in Kolozsvár, the best place
          where a Hungarian student could study physics in Romania. While I was
          finishing college, my father managed to defect during a government
          sponsored and supervised business trip to the US. As expected, family
          members left behind were subjected to reprisals. I found myself
          teaching high school children in Copșa Mică, one of the most polluted
          mining towns in all of Europe, a place where average life expectancy
          was 43.
        </p>
        <p>
          As the Romanian Revolution started in 1989, I managed to grab that
          first window of opportunity to come to the US and join the PhD program
          in physics at Rutgers. These were the heady days of the expansion of
          the Internet. I got involved in discussions with friends on how to
          deliver high quality video over the nascent Internet infrastructure.
          Those discussions led to collaboration on developing new technologies
          for video compression and delivery. I left graduate school to start my
          first company, Pulsent, and wrote the code that got the company
          funded. All of that technology is owned by Intel today. What we
          developed is used for key parts of the transmission of all video over
          the Internet.
        </p>
        <p>
          In the early 2000s I had to undergo some medical treatments where the
          side effects the medicines were appalling. All of us and our family
          members need treatments and tune-ups at various points in our lives.
          It can be an eye-opening experience when the available medicines are
          of limited efficacy and involve a mile-long list of side effects. I
          started discussing with my Pulsent cofounder and a small close group
          of friends the need to develop better drugs. Over time we started
          Verseon to change how the world develops new medicines. We hope that
          someday our work will make a meaningful impact on global health.
        </p>
        <p>
          When I have the opportunity, I love to travel and capture the beauty
          of our world though my photography.
        </p>
      </>
    );

    let desktop_html = (
      <Container className="home-container photo-art">
        <br />
        <br />
        <Row>
          <Col lg="6" className="signature-with-profile-image-col">
            <img
              src={signature}
              alt="signature"
              width="100%"
              className="signature-image"
            />
            <div className="signature-text">
              Technologist | Entrepreneur | Artist
            </div>
          </Col>
          <Col lg="6" className="signature-with-profile-image-col">
            <img
              src={profile_image}
              alt="profile"
              width="100%"
              className="profile-image"
            />
          </Col>
        </Row>
        <br />
        <br />
        <Row className="display-images-div-row">
          <Col lg="7" className="display-images-background">
            <div className="home-outer-box photo-art-column-reset">
              <div className="home-inner-box photo-art with-border ">
                <a href={PHOTO_ART_ROOT_URL}>
                  <div
                    className={`home-inner-box-content photo-art ${
                      active ? "active" : ""
                    } ${index_n % 2 === 0 ? "even" : "odd"}`}
                    id="photo-art-image"
                    style={{
                      backgroundImage: `url(${BASE_URL}/cms-system/uploads/${posts_filtered[index_n].url})`,
                    }}
                  ></div>
                </a>
              </div>
            </div>
          </Col>
          <Col lg="5" className="">
            <div className="my-photography-text">My Photography</div>
          </Col>
        </Row>

        <Row className="mt-4" id="about-me">
          <Col lg="12">
            <div className="about-me-title">About Me</div>

            <div className="about-me-text">{about_me_content}</div>
          </Col>
        </Row>
        {/* 
        <Row className="photo-write-row margin-bottom-70 container">
          <Col lg="4" className="home-outer-box photo-art-column-reset">
            <div className="home-inner-box photo-art with-border">
              <a href={PHOTO_ART_ROOT_URL}>
                <div
                  className={`home-inner-box-content photo-art ${
                    active ? "active" : ""
                  }`}
                  id="photo-art-image"
                  style={{
                    backgroundImage: `url(${BASE_URL}/cms-system/uploads/${posts_filtered[index_n].url})`,
                  }}
                ></div>
              </a>
            </div>
            <div className="box-title home-page-box-title">PHOTO ART</div>
          </Col>
          <Col lg="3" className="photo-art-column-reset"></Col>
          <Col
            lg="4"
            className="home-outer-box  photo-art-column-reset writing-section"
            onClick={() => {
              if (!this.state.isModalOpen) {
                this.setState({ isModalOpen: true });
              }
            }}
          >
            <div className="home-inner-box with-border">
              <div className="home-inner-box-content writing"></div>
            </div>
            <div className="box-title home-page-box-title">writings</div>
            <div
              className={`coming-soon-div ${
                this.state.isModalOpen ? "show" : ""
              }`}
            >
              <div>
                Coming soon.
                <br /> Check back in a week.
              </div>
              <br />
              <Button onClick={() => this.setState({ isModalOpen: false })}>
                OK
              </Button>
            </div>
          </Col>
        </Row>
 */}
        {/* {Tools.is_less_than(1200) ? <BioMobile /> : <Bio />} */}
      </Container>
    );
    let mobile_html = (
      <div id="mobile-home-main-div">
        <div className="margin-top-mobileview"></div>
        <div className="mobile-home-profile-image-div">
          <img
            src={profile_mobile_image}
            alt="profile"
            width="100%"
            className="profile-mobile-image"
          />
          <img
            src={signature}
            alt="signature"
            width="60%"
            className="signature-mobile-image"
          />
        </div>

        <div className="signature-mobile-text">
          Technologist | Entrepreneur | Artist
        </div>

        <br />
        <br />

        <a href={PHOTO_ART_ROOT_URL}>
          <div
            className={`same-edge-container ${
              index_n % 2 === 0 ? "even" : "odd"
            }`}
            id="photo-art-image"
            style={{
              backgroundImage: `url(${BASE_URL}/cms-system/uploads/${posts_filtered[index_n].url})`,
            }}
          ></div>
        </a>
        <div className="box-title">My Photography</div>

        <br />
        <div className="mobile-home-about-me">
          <div className="about-me-title">About Me</div>

          <div className="about-me-text">{about_me_content}</div>
        </div>

        {/* <div className="box-title mt-n4">&</div>

        <div
          className="same-edge-container writing-image writing-section"
          id="photo-art-image"
          onClick={() => {
            if (!this.state.isModalOpen) {
              this.setState({ isModalOpen: true });
            }
          }}
        >
          <div
            className={`coming-soon-div ${
              this.state.isModalOpen ? "show" : ""
            }`}
          >
            <div>
              Coming soon.
              <br /> Check back in a week.
            </div>
            <br />
            <Button onClick={() => this.setState({ isModalOpen: false })}>
              OK
            </Button>
          </div>
        </div>

        <div className="box-title">writings</div>
        <br />
        <br />
        <br />
        <BioMobile /> */}
        {/* <div className="bio-text">
          <p>
            Hello, and welcome to my place on the web. This is meant to be a
            waypoint from where you can head off to explore my photo art or
            writings.{" "}
          </p>
          <p>
            Don’t let me keep you from exploring further. If you wish to know a
            bit more about me here is a little narrative in my own words.
          </p>
          <br />
          <img src={bio_image_01} alt="bio profile" />
          <br />
          <br />
          <p>
            While growing up, I thought I was going to be an academic pursuing a
            research career in theoretical physics. While in college I had the
            pleasure of interacting with one mathematics professor, Anatole
            Katok, who made me realize that I loved math even more than physics.
            Eventually as I started working on some research problems in
            mathematical physics, I began to notice that most of the bright kids
            I knew in the late eighties and early nineties were leaving academia
            for careers on Wall Street or in industry. A few years later Phil
            Anderson wrote a{" "}
            <a
              className="bio-link-text"
              href="https://physicstoday.scitation.org/doi/10.1063/1.882812"
              target="_blank"
              rel="noreferrer"
            >
              nice article
            </a>{" "}
            about that in Physics Today.
          </p>
          <p>
            The expansion of the Internet was just starting and I started
            discussing with a friend how to process video for more efficient
            delivery. It was a mathematical problem whose solution, it seemed to
            me, could underpin the then emerging world of media delivery on the
            Internet. The problem also satisfied my criteria for what should
            form the basis for a business venture with large potential –
            exploration of an emerging field at the intersection of traditional
            business segments; in this case the intersection of computers,
            networking and traditional media and entertainment. I left academia
            to start my first company, Pulsent. We developed some interesting
            technologies that, according to various subject matter experts and
            pioneers, were the only breakthroughs to have come along in the
            field since the development of video processing and compression
            technology in the late seventies. Intel owns all that technology now
            and key parts of the transmission of all video on the Internet today
            rely on technology that we built.
          </p>
          <p>
            In the early aughts two of my colleagues and I were considering
            ideas for our next adventure. Working at the intersection of
            high-tech and biotech seemed like an interesting thing to do. What
            if we could build the modeling tools for constructing completely new
            drug molecules that bind to specific proteins in our bodies and
            change their functions? That idea has led us through a very long
            journey of developing many advancements in molecule engineering that
            today form the basis for{" "}
            <a
              className="bio-link-text"
              href="https://www.verseon.com/"
              target="_blank"
              rel="noreferrer"
            >
              Verseon
            </a>
            ’s drug design platform. Along the way, we are still going through
            the trials and tribulations of building a pharmaceutical company.
          </p>
          <p>
            If you have read this far, I only have one request of you. Judge my
            photography or writings on their own merits, without consideration
            for anything else I have done. Each of those endeavors should stand
            on its own as if that is the primary thing I do.
          </p>
          <br />
          <br />
        </div> */}
        <div className="scroll-to-top">
          {is_visible && (
            <div onClick={() => this.scrollToTop()}>
              <span className="material-icons">expand_circle_down</span>
            </div>
          )}
        </div>
        {/* <Modal isOpen={this.state.isModalOpen} className="app-modal-copyright">
          <ModalBody>Coming soon. Check back in a week.</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.setState({ isModalOpen: false });
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal> */}
      </div>
    );

    // const is_less_than_1000 = Tools.is_less_than(1000);
    if (!Tools.is_less_than(1000)) {
      return <>{desktop_html}</>;
    } else {
      return <>{mobile_html}</>;
    }
    // return (
    //   <>
    //     {Tools.is_less_than(1000) && <div>less than 1000</div>}
    //     {!isTablet && <BrowserView>{desktop_html}</BrowserView>}
    //     <MobileView>{mobile_html} </MobileView>
    //   </>
    // );
  }
}
// export default Home;

const mapStateToProps = (state) => {
  return {
    posts: state.post.posts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_posts: () => dispatch(actions.get_posts()),
    // delete_post: (payload) => dispatch(actions.delete_post(payload)),
    // update_post: (payload) => dispatch(actions.update_post(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
