import * as actionTypes from "../actions/actionTypes";

const initialState = {
  // token: null,
  // userId: null,
  // isAuth: false,
  is_gallery: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MESSAGE:
      //   console.log("set message reducer", action);
      const { name, value } = action.payload;
      return {
        ...state,
        [name]: value,
      };
    default:
      return state;
  }
};

export default reducer;
