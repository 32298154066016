import React from "react";
import { Redirect } from "react-router-dom";

const logout = (props) => {
  console.log(props);
  const { location, history } = props;
  const { logout } = location;
  const { replace } = history;

  console.log("logout", logout, replace);
  logout();
  // replace("/admin");

  return <Redirect to="/admin" />;
};

export default logout;
