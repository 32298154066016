import React from "react";
import { connect } from "react-redux";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import "./CreateCategory.css";
import * as actions from "../../store/actions/index";

class CreateCategory extends React.Component {
  state = {
    category: "",
    selected_item: null,
  };
  componentDidMount() {
    const { get_categories } = this.props;
    get_categories();
  }

  createCate = () => {
    const { category } = this.state;
    const { add_category } = this.props;

    const payload = {
      name: category,
    };
    add_category(payload);
    this.setState({ category: "" });
  };

  onChangeCategoryHandler = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    this.setState({
      [name]: value,
    });
  };

  onChangeUpdateCategoryHandler = (e) => {
    const { value } = e.target;
    this.setState(
      {
        ...this.state,
        selected_item: {
          ...this.state.selected_item,
          name: value,
        },
      },
      () => console.log("change cate", this.state)
    );
  };

  updateCate = () => {
    const { selected_item } = this.state;
    const { update_category } = this.props;
    update_category(selected_item);
    this.setState({
      selected_item: null,
    });
  };

  deleteCate = (id) => {
    // const number_id = Number(id);

    const { delete_category } = this.props;
    delete_category({ id });
    this.setState({
      selected_item: null,
    });
  };

  onClickDelete = (cat) => {
    this.setState({
      delete_cat: cat,
      modal: true,
    });
  };

  onClickDeleteConfirm = () => {
    const { delete_category } = this.props;
    const { delete_cat } = this.state;
    delete_category({ id: delete_cat.id });
    this.setState({
      delete_cat: null,
      modal: false,
    });
  };

  onClickUpdateOrderConfirm = (cat) => {
    const { update_category_order } = this.props;
    console.log(cat);
    update_category_order({ id: cat.id });
  };

  onClickDeleteCancel = () => {
    this.setState({
      delete_cat: null,
      modal: false,
    });
  };

  create_comparator = (key) => {
    return (a, b) => {
      let val_a = a[key];
      let val_b = b[key];

      if (val_a < val_b) {
        return 1;
      }
      if (val_a > val_b) {
        return -1;
      }
      return 0;
    };
  };

  sortByID = () => {
    return (a, b) => {
      if (a.id === "1" && b.id !== "1") {
        return -1;
      }
      return 0;
    };
  };

  render() {
    const { category, selected_item } = this.state;
    console.log("selected_item", selected_item);
    const { categories } = this.props;
    if (categories.length === 0) {
      return null;
    }
    categories.sort(this.create_comparator("display_id"));
    categories.sort(this.sortByID());
    // console.log("id abc--", categories);

    return (
      <div className="admin-images-list-main">
        <div className="admin-header mb-5">Gallery</div>
        <div className="row mt-5">
          {categories.map((cat) => {
            const isSelected = selected_item && cat.id === selected_item.id;
            return (
              <React.Fragment key={cat.id}>
                <div
                  className={`col-sm-5 mt-2 mb-2 ml-3 ${
                    isSelected ? "selected-category-active" : ""
                  }`}
                >
                  {/* "col-sm-5 mt-2 mb-2 selected-category-active" */}
                  {/* {cat.id} - {cat.name}{" "} */}
                  {cat.name}
                </div>
                <div className="col-sm-2 mt-2 mb-2">
                  <Button
                    outline={!isSelected}
                    disabled={isSelected}
                    color="secondary"
                    size="sm"
                    onClick={() => this.setState({ selected_item: cat })}
                  >
                    Edit
                  </Button>
                </div>
                <div className="col-sm-2 ml-1 mt-2 mb-2">
                  {cat.deletable === "1" && (
                    <Button
                      outline={!isSelected}
                      disabled={isSelected}
                      color="primary"
                      size="sm"
                      // onClick={() => this.setState({ selected_item: cat })}
                      onClick={() => this.onClickUpdateOrderConfirm(cat)}
                    >
                      Move to top
                    </Button>
                  )}
                </div>

                <div className="col-sm-2 ml-1 mt-2 mb-2">
                  {cat.deletable === "1" && (
                    <Button
                      outline
                      color="danger"
                      size="sm"
                      // onClick={() => this.deleteCate(cat.id)}
                      onClick={() => this.onClickDelete(cat)}
                    >
                      Delete
                    </Button>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <hr />
        <Form>
          {!selected_item && (
            <FormGroup row className="ml-1">
              <Label for="post-title" sm={2}>
                New Gallery
              </Label>
              <Col sm={6}>
                <Input
                  type="text"
                  name="category"
                  id="category"
                  placeholder="Enter gallery name"
                  value={category}
                  onChange={this.onChangeCategoryHandler}
                />
              </Col>
              <Col sm={4}>
                <Button
                  outline
                  color="secondary"
                  onClick={() => this.createCate()}
                  value={category}
                  onChange={this.onChangeCategoryHandler}
                >
                  Create
                </Button>
              </Col>
            </FormGroup>
          )}

          {selected_item && (
            <FormGroup row className="ml-1">
              <Label for="post-title" sm={2}>
                Update Gallery
              </Label>
              <Col sm={5}>
                <Input
                  type="text"
                  name="update_category"
                  id="update_category"
                  placeholder="Enter category name"
                  value={selected_item ? selected_item.name : ""}
                  onChange={this.onChangeUpdateCategoryHandler}
                />
              </Col>
              <Col sm={2}>
                <Button
                  outline
                  color="secondary"
                  onClick={() => this.updateCate()}
                  // value={category}
                  // onChange={this.onChangeCategoryHandler}
                >
                  Update
                </Button>
              </Col>
              <Col sm={2} className="ml-3">
                <Button
                  outline
                  color="secondary"
                  onClick={() => this.setState({ selected_item: null })}
                  // value={category}
                  // onChange={this.onChangeCategoryHandler}
                >
                  Cancel
                </Button>
              </Col>
            </FormGroup>
          )}
        </Form>
        <Modal isOpen={this.state.modal}>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalBody>
            Images belong to category (
            {this.state.delete_cat ? this.state.delete_cat.name : ""}) will move
            to default category
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onClickDeleteConfirm}>
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.onClickDeleteCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.category.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_categories: () => dispatch(actions.get_categories()),
    add_category: (payload) => dispatch(actions.add_category(payload)),
    update_category: (payload) => dispatch(actions.update_category(payload)),
    delete_category: (payload) => dispatch(actions.delete_category(payload)),
    update_category_order: (payload) =>
      dispatch(actions.update_category_order(payload)),
  };
};

// export default CreateCategory;

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory);
