import * as actionTypes from "../actions/actionTypes";

const initialState = {
  // token: null,
  // userId: null,
  // isAuth: false,
  categories: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORIES:
        console.log("set categories reducer", action);
      const { payload } = action;
      return {
        ...state,
        categories: payload,
      };
    default:
      return state;
  }
};

export default reducer;
