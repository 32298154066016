import { combineReducers } from "redux";

import user from "./user";
import message from "./message";
import category from "./category";
import post from "./post";
import location from "./location";
import writing_categories from "./writing_category";
import writing from "./writing";

const rootReducer = combineReducers({
  user: user,
  message: message,
  category: category,
  location: location,
  post: post,
  writing_categories: writing_categories,
  writing: writing,
});

export default rootReducer;
