import React from "react";
import { connect } from "react-redux";
// import axios from "axios";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import "./CreateCategory.css";
// import { BASE_URL } from "../../consts/const";
import * as actions from "../../store/actions/index";

class CreateLocation extends React.Component {
  state = {
    location: "",
    selected_item: null,
  };
  componentDidMount() {
    const { get_locations } = this.props;
    get_locations();
  }

  createLocationSubmit = () => {
    const { location } = this.state;
    const { add_location } = this.props;

    const payload = {
      name: location,
    };
    add_location(payload);
    this.setState({
      location: "",
    });
  };

  onChangeLocationHandler = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    this.setState({
      [name]: value,
    });
  };

  onChangeUpdateLocationHandler = (e) => {
    const { value } = e.target;
    this.setState(
      {
        ...this.state,
        selected_item: {
          ...this.state.selected_item,
          name: value,
        },
      },
      () => console.log("change cate", this.state)
    );
  };

  updateLocationSubmit = () => {
    const { selected_item } = this.state;
    const { update_location } = this.props;
    update_location(selected_item);
    this.setState({
      selected_item: null,
    });
  };

  deleteLocationSubmit = (id) => {
    // const number_id = Number(id);

    const { delete_location } = this.props;
    delete_location({ id });
    this.setState({
      selected_item: null,
    });
  };

  onClickDelete = (loc) => {
    this.setState({
      delete_loc: loc,
      modal: true,
    });
  };

  onClickDeleteConfirm = () => {
    const { delete_location } = this.props;
    const { delete_loc } = this.state;
    delete_location({ id: delete_loc.id });
    this.setState({
      delete_loc: null,
      modal: false,
    });
  };

  onClickDeleteCancel = () => {
    this.setState({
      delete_loc: null,
      modal: false,
    });
  };

  render() {
    const { location, selected_item } = this.state;
    const { locations } = this.props;
    if (locations.length === 0) {
      return null;
    }
    return (
      <div className="admin-images-list-main">
        <div className="admin-header mb-5">Location</div>
        <div className="row mt-5">
          {locations.map((cat) => {
            const isSelected = selected_item && cat.id === selected_item.id;
            return (
              <React.Fragment key={cat.id}>
                <div
                  className={`col-sm-5 mt-2 mb-2  ml-3 ${
                    isSelected ? "selected-category-active" : ""
                  }`}
                >
                  {/* "col-sm-5 mt-2 mb-2 selected-category-active" */}
                  {cat.name}
                </div>
                <div className="col-sm-2 mt-2 mb-2">
                  <Button
                    outline={!isSelected}
                    disabled={isSelected}
                    color="secondary"
                    size="sm"
                    onClick={() => this.setState({ selected_item: cat })}
                  >
                    Edit
                  </Button>
                </div>

                <div className="col-sm-2 ml-4 mt-2 mb-2">
                  {cat.deletable === "1" && (
                    <Button
                      outline
                      color="danger"
                      size="sm"
                      // onClick={() => this.deleteLocationSubmit(cat.id)}
                      onClick={() => this.onClickDelete(cat)}
                    >
                      Delete
                    </Button>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <hr />
        <Form>
          {!selected_item && (
            <FormGroup row className="ml-1">
              <Label for="location" sm={2}>
                New Location
              </Label>
              <Col sm={6}>
                <Input
                  type="text"
                  name="location"
                  id="location"
                  placeholder="Enter location name"
                  value={location}
                  onChange={this.onChangeLocationHandler}
                />
              </Col>
              <Col sm={4}>
                <Button
                  outline
                  color="secondary"
                  onClick={() => this.createLocationSubmit()}
                  // value={category}
                  onChange={this.onChangeLocationHandler}
                >
                  Create
                </Button>
              </Col>
            </FormGroup>
          )}

          {selected_item && (
            <FormGroup row className="ml-1">
              <Label for="update_location" sm={2}>
                Update location
              </Label>
              <Col sm={5}>
                <Input
                  type="text"
                  name="update_location"
                  id="update_location"
                  placeholder="Enter location name"
                  value={selected_item ? selected_item.name : ""}
                  onChange={this.onChangeUpdateLocationHandler}
                />
              </Col>
              <Col sm={2}>
                <Button
                  outline
                  color="secondary"
                  onClick={() => this.updateLocationSubmit()}
                  // value={category}
                  // onChange={this.onChangeCategoryHandler}
                >
                  Update
                </Button>
              </Col>
              <Col sm={2} className="ml-3">
                <Button
                  outline
                  color="secondary"
                  onClick={() => this.setState({ selected_item: null })}
                  // value={category}
                  // onChange={this.onChangeCategoryHandler}
                >
                  Cancel
                </Button>
              </Col>
            </FormGroup>
          )}
        </Form>
        <Modal isOpen={this.state.modal}>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalBody>
            Images belong to location (
            {this.state.delete_loc ? this.state.delete_loc.name : ""}) will move
            to default location
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onClickDeleteConfirm}>
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.onClickDeleteCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.location.locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_locations: () => dispatch(actions.get_locations()),
    add_location: (payload) => dispatch(actions.add_location(payload)),
    update_location: (payload) => dispatch(actions.update_location(payload)),
    delete_location: (payload) => dispatch(actions.delete_location(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLocation);
