import * as actionTypes from "./actionTypes";

export const get_locations = () => {
  console.log("get_locations action");
  return {
    type: actionTypes.GET_LOCATIONS,
  };
};

export const set_locations = (payload) => {
  // console.log("set_locations action");
  return {
    type: actionTypes.SET_LOCATIONS,
    payload,
  };
};

export const add_location = (payload) => {
  // payload {name: location}
  console.log("add_location action");
  return {
    type: actionTypes.ADD_LOCATION,
    payload,
  };
};


export const update_location = (payload) => {
  // payload {name: location}
  console.log("update_location action");
  return {
    type: actionTypes.UPDATE_LOCATION,
    payload,
  };
};

export const delete_location = (payload) => {
  // payload {name: catetory}
  console.log("delete_location action", payload, "==");
  return {
    type: actionTypes.DELETE_LOCATION,
    payload,
  };
};