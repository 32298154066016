import * as actionTypes from "./actionTypes";

export const get_writing_categories = () => {
  console.log("get_writing_categories action");
  return {
    type: actionTypes.GET_WRITING_CATEGORIES,
  };
};

export const set_writing_categories = (payload) => {
  // console.log("set_writing_categories action");
  return {
    type: actionTypes.SET_WRITING_CATEGORIES,
    payload,
  };
};

export const add_writing_category = (payload) => {
  // payload {name: catetory}
  console.log("add_writing_category action");
  return {
    type: actionTypes.ADD_WRITING_CATEGORY,
    payload,
  };
};

export const update_writing_category = (payload) => {
  // payload {name: catetory}
  console.log("update_writing_category action");
  return {
    type: actionTypes.UPDATE_WRITING_CATEGORY,
    payload,
  };
};

export const delete_writing_category = (payload) => {
  // payload {name: catetory}
  console.log("delete_writing_category action", payload, "==");
  return {
    type: actionTypes.DELETE_WRITING_CATEGORY,
    payload,
  };
};

export const update_writing_category_order = (payload) => {
  // payload {name: catetory}
  console.log("update_writing_category_order action", payload, "==");
  return {
    type: actionTypes.UPDATE_WRITING_CATEGORY_ORDER,
    payload,
  };
};
