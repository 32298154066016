import axios from "axios";
import { put } from "redux-saga/effects";

// import { push } from 'react-router-redux';
import { delay } from "redux-saga/effects";
import { BASE_URL } from "../../consts/const";
import decode from "jwt-decode";

import * as actions from "../actions/index";

// new

export function* isAuthSaga() {
  console.log("i am isAUthSaga");
  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // "Content-Type":"pplication/json",
      // Secret: token,
      Authorization: `Bearer ${token || ""}`,
    },
  };

  try {
    const result = yield axios.get(
      `${BASE_URL}/cms-system/api/user/auth.php`,
      header
    );

    console.log(result.data);

    yield put(actions.set_auth(true));

    const decoded = decode(token);
    const current_time = new Date().getTime() / 1000;
    // console.log("decoded", decoded.expired - current_time);
    const expired_in = (decoded.expired - current_time) * 1000;
    yield delay(expired_in);
    yield put(actions.logout());
  } catch (err) {
    // onSetAuth(false);
    yield put(actions.set_auth(false));
  }
}

export function* logoutSaga(action) {
  // console.log("i am logoutSaga", action);
  // const {history} = action
  yield localStorage.removeItem("token");

  // history.push("/admin/login");
  yield put(actions.is_auth());
}

export function* loginSaga(action) {
  console.log("i am loginSaga", action);
  const { payload } = action;
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  yield put(
    actions.set_message({
      name: "admin_login_failed",
      value: null,
    })
  );
  try {
    const response = yield axios.post(
      `${BASE_URL}/cms-system/api/user/login.php`,
      payload,
      header
    );
    console.log("response login", response.data.token);
    const { token } = response.data;
    localStorage.setItem("token", token);

    yield put(actions.is_auth());
    yield put(actions.check_writing_files());
  } catch (error) {
    console.log(error);
    // this.setState({
    //   error_msg: "Login failed, please try again",
    // });
    yield put(
      actions.set_message({
        name: "admin_login_failed",
        value: "Please check username and password, and try again",
      })
    );
  }
}

export function* getCodeSaga(action) {
  console.log("i am loginSaga", action);
  const { payload } = action;
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  yield put(
    actions.set_message({
      name: "admin_get_code_failed",
      value: null,
    })
  );
  yield put(
    actions.set_message({
      name: "admin_get_code_success",
      value: null,
    })
  );
  try {
    const response = yield axios.post(
      `${BASE_URL}/cms-system/api/verify/create.php`,
      payload,
      header
    );
    console.log("response", response);
    yield put(
      actions.set_message({
        name: "admin_get_code_success",
        value: true,
      })
    );

    // yield put(actions.is_auth());
  } catch (error) {
    console.log(error);

    yield put(
      actions.set_message({
        name: "admin_get_code_failed",
        value: "Please make sure the email you entered is correct!",
      })
    );
  }
}

export function* resetPasswordSaga(action) {
  console.log("i am resetPasswordSaga", action);
  const { payload } = action;
  const header = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  yield put(
    actions.set_message({
      name: "admin_reset_password_failed",
      value: null,
    })
  );
  yield put(
    actions.set_message({
      name: "admin_reset_password_success",
      value: null,
    })
  );
  try {
    const response = yield axios.post(
      `${BASE_URL}/cms-system/api/user/update.php`,
      payload,
      header
    );
    console.log("response", response);
    yield put(
      actions.set_message({
        name: "admin_reset_password_success",
        value: true,
      })
    );

    // yield put(actions.is_auth());
  } catch (error) {
    console.log(error);

    yield put(
      actions.set_message({
        name: "admin_reset_password_failed",
        value: "Please verify the data you entered is correct!",
      })
    );
  }
}
