import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";

import * as actions from "../../store/actions/index";
import "./Login.css";
import { BASE_URL } from "../../consts/const";

class Login extends React.Component {
  state = {
    username: "",
    password: "",
    captcha: "",
    sign_in_disable: true,
    captcha_disable: false,
    random_number: "",
  };

  componentDidMount() {
    this.setState({
      random_number: Math.floor(Math.random() * 1000000) + 1,
    });
  }

  onChangeHandler = (e) => {
    // console.log(e.target);
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onChangeCaptchaHandler = (e) => {
    const { random_number } = this.state;
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      async () => {
        // console.log(this.state);

        if (value.length < 6) {
          return;
        }
        const header = {
          headers: {
            "Content-Type": "application/ix-www-form-urlencoded",
          },
        };
        const res = await axios.post(
          `${BASE_URL}/cms-system/api/captcha/check.php`,
          {
            captcha_code: value,
            rand_num: random_number,
          },
          header
        );
        if (res.data) {
          this.setState({
            sign_in_disable: false,
            captcha_disable: true,
          });
          ReactDOM.findDOMNode(this.myInputRef).focus();
        } else {
          console.log("false222");
        }
      }
    );
  };

  loginHandler = async (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    const { login } = this.props;

    const payload = {
      email: username,
      password,
    };
    login(payload);
  };

  render() {
    const {
      username,
      password,
      captcha,
      sign_in_disable,
      captcha_disable,
      random_number,
    } = this.state;
    const { admin_login_failed } = this.props;
    return (
      <div className="login-main-div">
        <Col sm={12} className="sign-in-header">
          Sign in
        </Col>
        <Form>
          <FormGroup>
            <Label for="username" sm={4}>
              Email Address
            </Label>
            <Col sm={12}>
              <Input
                type="text"
                name="username"
                id="username"
                // placeholder="Enter username"
                value={username}
                onChange={this.onChangeHandler}
              />
            </Col>
          </FormGroup>

          <FormGroup>
            <Label for="password" sm={4}>
              Password
            </Label>
            <Col sm={12}>
              <Input
                type="password"
                name="password"
                id="password"
                // placeholder="Enter password"
                value={password}
                // defaultValue=""
                onChange={this.onChangeHandler}
              />
            </Col>
          </FormGroup>
          <FormGroup className="captcha">
            <Label for="username" sm={4}>
              Enter the code
            </Label>
            <Row>
              <Col sm={5}>
                <Input
                  type="text"
                  name="captcha"
                  id="captcha"
                  // placeholder="Enter captcha code"
                  value={captcha}
                  maxLength={6}
                  disabled={captcha_disable}
                  onChange={this.onChangeCaptchaHandler}
                />
              </Col>
              <Col sm={6} className="captcha-img">
                <img
                  src={`${BASE_URL}/cms-system/api/captcha/captcha.php?rand=${random_number}`}
                  id="captchaimg"
                  width="230px"
                  height="60px"
                  alt="captcha"
                />
              </Col>
            </Row>
          </FormGroup>
          <br />
          <FormGroup>
            <Col sm={12}>
              <Button
                // outline
                color="secondary"
                type="submit"
                onClick={this.loginHandler}
                disabled={sign_in_disable}
                ref={(c) => (this.myInputRef = c)}
              >
                Sign in
              </Button>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={12}>
              <Link to="/admin/resetpassword">Forgot password</Link>
            </Col>
          </FormGroup>

          <Col sm={12}>
            {!!admin_login_failed && (
              <Alert color="warning">{admin_login_failed}</Alert>
            )}
          </Col>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admin_login_failed: state.message.admin_login_failed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    is_auth: () => dispatch(actions.is_auth()),
    login: (payload) => dispatch(actions.login(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
