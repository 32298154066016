import React from "react";
import { Route, Switch } from "react-router-dom";

// import CreatePost from "./admin/CreatePost";
// import CreateCategory from "./admin/CreateCategory";
// import ShowImages from "./admin/ShowImages";
import Login from "./admin/Login";
import ReseetPassword from "./admin/ResetPassword";
import ReseetPassword2 from "./admin/ResetPassword2";
// import Logout from "./admin/logout";

import Dashboard from "./admin/dashboard";

// import Home from "./home/Home";
// import PhotoArt from "./home/PhotoArt";
// import WriteUp from "./home/WriteUp";
import Index from "./home/index";

const routes = (props) => {
  const { isAuth } = props;
  console.log("router", isAuth);
  return (
    <div>
      {isAuth && (
        <Switch>
          <Route path="/admin" component={Dashboard} />

          {/* <Route path="/photo-art" component={PhotoArt} />
          <Route path="/write-up" component={WriteUp} /> */}
          <Route path="/" component={Index} />
          {/* <Route path="/admin/post/add" exact component={CreatePost} /> */}
          {/* <Route path="/admin/post" component={ShowImages} />
          <Route path="/admin/category" component={CreateCategory} />
          <Route path="/admin/logout" component={Logout} />
          <Route path="/admin" component={ShowImages} />

          <Route path="/" component={Home} /> */}
        </Switch>
      )}
      {!isAuth && (
        <Switch>
          <Route path="/admin/resetpassword" component={ReseetPassword} />
          <Route path="/admin/resetpassword2" component={ReseetPassword2} />
          <Route path="/admin" component={Login} />
          {/* <Route path="/photo-art" component={PhotoArt} />
          <Route path="/write-up" component={WriteUp} />
          <Route path="/" component={Home} /> */}
          <Route path="/" component={Index} />
        </Switch>
      )}
    </div>
  );
};

export default routes;
