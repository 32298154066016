import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import * as actions from "../../../store/actions/index";
import { BASE_URL } from "../../../consts/const";
import "./WritingCategory.css";

class WritingCategory extends Component {
  state = {
    category: "",
    selected_item: null,
  };
  componentDidMount() {
    const { get_writing_categories } = this.props;
    get_writing_categories();
  }

  createCate = () => {
    const { category, file } = this.state;
    const { add_writing_category } = this.props;

    const payload = {
      file,
      cat: {
        name: category,
      },
    };
    add_writing_category(payload);
    this.setState({
      category: "",
      previewUrl: null,
      file: null,
    });
  };

  onClickDelete = (cat) => {
    this.setState({
      delete_cat: cat,
      modal: true,
    });
  };

  onClickDeleteConfirm = () => {
    const { delete_writing_category } = this.props;
    const { delete_cat } = this.state;
    delete_writing_category({ id: delete_cat.id });
    this.setState({
      delete_cat: null,
      modal: false,
    });
  };

  onClickDeleteCancel = () => {
    this.setState({
      delete_cat: null,
      modal: false,
    });
  };

  onClickUpdateOrderConfirm = (cat) => {
    const { update_writing_category_order } = this.props;
    console.log(cat);
    update_writing_category_order({ id: cat.id });
  };

  onChangeCategoryHandler = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    this.setState({
      [name]: value,
    });
  };

  onChangeUpdateCategoryHandler = (e) => {
    const { value } = e.target;
    this.setState(
      {
        ...this.state,
        selected_item: {
          ...this.state.selected_item,
          name: value,
        },
      },
      () => console.log("change cate", this.state)
    );
  };

  updateCate = () => {
    const { selected_item, file } = this.state;
    console.log("selected_item", selected_item, file);

    const payload = {
      file,
      cat: selected_item,
    };

    const { update_writing_category } = this.props;
    update_writing_category(payload);
    this.setState({
      selected_item: null,
      category: "",
      previewUrl: null,
      file: null,
    });
  };

  create_comparator = (key) => {
    return (a, b) => {
      let val_a = a[key];
      let val_b = b[key];

      if (val_a < val_b) {
        return 1;
      }
      if (val_a > val_b) {
        return -1;
      }
      return 0;
    };
  };

  sortByID = () => {
    return (a, b) => {
      if (a.id === "1" && b.id !== "1") {
        return -1;
      }
      return 0;
    };
  };

  // image picker
  filePickerRef = React.createRef();
  pickImageHandler = () => {
    this.filePickerRef.current.click();
  };
  pickedHandler = (event) => {
    const { isValid } = this.state;
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      this.setState({
        isValid: true,
        file: pickedFile,
      });
      fileIsValid = true;
      const file = pickedFile;
      console.log("file", file.size);
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        // setPreviewUrl(fileReader.result);
        this.setState({ previewUrl: fileReader.result });
      };
    } else {
      this.setState({
        isValid: false,
      });
      fileIsValid = false;
    }
    console.log("pickedFile, fileIsValid", pickedFile, fileIsValid);
  };
  // image picker end

  render() {
    const { writing_categories } = this.props;
    const { category, selected_item, previewUrl } = this.state;
    // if (writing_categories.length === 0) {
    //   return null;
    // }

    writing_categories.sort(this.create_comparator("display_id"));
    writing_categories.sort(this.sortByID());

    console.log("writing_categories", writing_categories);
    return (
      <div className="admin-images-list-main">
        <div className="admin-header mb-5">Category</div>
        <div className="row mt-5">
          {writing_categories.map((cat) => {
            const isSelected = selected_item && cat.id === selected_item.id;
            return (
              <React.Fragment key={cat.id}>
                <div
                  className={`col-sm-3 mt-2 mb-2 ml-3 ${
                    isSelected ? "selected-category-active" : ""
                  }`}
                >
                  {/* "col-sm-5 mt-2 mb-2 selected-category-active" */}
                  {/* {cat.id} - {cat.name}{" "} */}
                  {cat.name}
                </div>
                <div className={`col-sm-2 mt-2 mb-2 ml-3`}>
                  <img
                    src={`${BASE_URL}/cms-system/uploads/category_images/${cat.image_url}`}
                    alt={cat.name}
                    width="30px"
                  />
                </div>
                <div className="col-sm-2 mt-2 mb-2">
                  <Button
                    outline={!isSelected}
                    disabled={isSelected}
                    color="secondary"
                    size="sm"
                    onClick={() => this.setState({ selected_item: cat })}
                  >
                    Edit
                  </Button>
                </div>
                <div className="col-sm-2 ml-1 mt-2 mb-2">
                  {cat.deletable === "1" && (
                    <Button
                      outline={!isSelected}
                      disabled={isSelected}
                      color="primary"
                      size="sm"
                      // onClick={() => this.setState({ selected_item: cat })}
                      onClick={() => this.onClickUpdateOrderConfirm(cat)}
                    >
                      Move to top
                    </Button>
                  )}
                </div>

                <div className="col-sm-2 ml-1 mt-2 mb-2">
                  {cat.deletable === "1" && (
                    <Button
                      outline
                      color="danger"
                      size="sm"
                      // onClick={() => this.deleteCate(cat.id)}
                      onClick={() => this.onClickDelete(cat)}
                    >
                      Delete
                    </Button>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <hr />
        <Form>
          {!selected_item && (
            <FormGroup row className="ml-1">
              <Label for="post-title" sm={2}>
                New Category
              </Label>
              <Col sm={6} className="admin-new-category-sub-div">
                <Input
                  type="text"
                  name="category"
                  id="category"
                  placeholder="Enter Category Name"
                  value={category}
                  onChange={this.onChangeCategoryHandler}
                />
              </Col>
              <Col sm={4}>
                <Button
                  outline
                  color="secondary"
                  onClick={() => this.createCate()}
                  value={category}
                  onChange={this.onChangeCategoryHandler}
                  disabled={!previewUrl || !category.trim()}
                >
                  Create
                </Button>
              </Col>
              <br />
              <br />
              <br />
              <Label
                className="admin-writing-category-label"
                for="post-title"
                sm={2}
              >
                Upload image
              </Label>
              <Col sm={6} className="admin-new-category-sub-div">
                <input
                  ref={this.filePickerRef}
                  type="file"
                  accept="image/gif, image/jpeg, image/png, image/svg+xml"
                  style={{ display: "none" }}
                  onChange={this.pickedHandler}
                />

                <div className="mb-2">
                  {previewUrl && (
                    <img
                      src={previewUrl}
                      alt="Preview"
                      width="50px"
                      onLoad={this.onload_image}
                    />
                  )}
                  {!previewUrl && <p>Please pick an image.</p>}
                </div>
                <Button
                  outline
                  color="primary"
                  type="button"
                  onClick={this.pickImageHandler}
                >
                  PICK IMAGE
                </Button>
              </Col>
            </FormGroup>
          )}

          {selected_item && (
            <FormGroup row className="ml-1">
              <Label for="post-title" sm={2}>
                Update Category
              </Label>
              <Col sm={5} className="admin-new-category-sub-div">
                <Input
                  type="text"
                  name="update_category"
                  id="update_category"
                  placeholder="Enter category name"
                  value={selected_item ? selected_item.name : ""}
                  onChange={this.onChangeUpdateCategoryHandler}
                />
              </Col>
              <Col sm={2}>
                <Button
                  outline
                  color="secondary"
                  onClick={() => this.updateCate()}
                  // value={category}
                  // onChange={this.onChangeCategoryHandler}
                >
                  Update
                </Button>
              </Col>
              <Col sm={2} className="ml-3">
                <Button
                  outline
                  color="secondary"
                  onClick={() =>
                    this.setState({
                      selected_item: null,
                      category: "",
                      previewUrl: null,
                      file: null,
                    })
                  }
                  // value={category}
                  // onChange={this.onChangeCategoryHandler}
                >
                  Cancel
                </Button>
              </Col>
              <br />
              <br />
              <Label
                className="admin-writing-category-label"
                for="post-title"
                sm={2}
              >
                Upload image
              </Label>
              <Col sm={5} className="admin-new-category-sub-div">
                <input
                  ref={this.filePickerRef}
                  type="file"
                  accept="image/gif, image/jpeg, image/png, image/svg+xml"
                  style={{ display: "none" }}
                  onChange={this.pickedHandler}
                />

                <div className="mb-2">
                  {previewUrl && (
                    <img
                      src={previewUrl}
                      alt="Preview"
                      width="50px"
                      onLoad={this.onload_image}
                    />
                  )}
                  {!previewUrl && (
                    <img
                      src={`${BASE_URL}/cms-system/uploads/category_images/${selected_item.image_url}`}
                      alt="prev pic"
                      width="50px"
                      onLoad={this.onload_image}
                    />
                  )}
                </div>
                <Button
                  outline
                  color="primary"
                  type="button"
                  onClick={this.pickImageHandler}
                >
                  Change Image
                </Button>
              </Col>
            </FormGroup>
          )}
        </Form>
        <Modal isOpen={this.state.modal}>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalBody>
            Images belong to category (
            {this.state.delete_cat ? this.state.delete_cat.name : ""}) will move
            to default category
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onClickDeleteConfirm}>
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.onClickDeleteCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/* <FormGroup>
          <input
            ref={this.filePickerRef}
            type="file"
            accept="image/gif, image/jpeg, image/png, image/svg+xml"
            style={{ display: "none" }}
            onChange={this.pickedHandler}
          />
          <div className="mb-2">
            {previewUrl && (
              <img
                src={previewUrl}
                alt="Preview"
                width="50px"
                onLoad={this.onload_image}
              />
            )}
            {!previewUrl && (
              <p onClick={this.pickImageHandler}>Please pick an image.</p>
            )}
          </div>
          <Button
            outline
            color="primary"
            type="button"
            onClick={this.pickImageHandler}
          >
            PICK IMAGE
          </Button>
        </FormGroup> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    writing_categories: state.writing_categories.writing_categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_writing_categories: () => dispatch(actions.get_writing_categories()),
    add_writing_category: (payload) =>
      dispatch(actions.add_writing_category(payload)),
    update_writing_category: (payload) =>
      dispatch(actions.update_writing_category(payload)),
    delete_writing_category: (payload) =>
      dispatch(actions.delete_writing_category(payload)),
    update_writing_category_order: (payload) =>
      dispatch(actions.update_writing_category_order(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WritingCategory);
