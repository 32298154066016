import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { connect } from "react-redux";
// const {  } = React;
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useSwipeable } from "react-swipeable";

import "./PhotoArtTopImg.css";
import * as actions from "../../../store/actions/index";

import zoomin_img from "../../../images/zoom_in.png";

const PhotoArtTopImg = forwardRef((props, ref) => {
  const [click_from_zoom_icon, setClickFromZoomIcon] = useState(true);
  const [enterArrowArea, setEnterArrowArea] = useState(false);
  const [iconsShow, setIconsShow] = useState(false);
  const [movingTimer, setMovingTimer] = useState(false);

  const [fullScreen, setFullScreen] = useState(false);

  const [click_to_change_screen, set_click_to_change_screen] = useState(false);

  const [img_left_offset, set_img_left_offset] = useState("0px");

  useEffect(() => {
    // const { enlarge_first } = props;
    // console.log("enlarge_first", enlarge_first, fullScreen);

    // correct
    const {
      set_first_show_slider_false,
      first_show_sliders,
      enter_slide_show,
    } = props;

    if (first_show_sliders) {
      enter_slide_show();
      set_first_show_slider_false();
      // onClickHandler();
      setClickFromZoomIcon(false);
    }

    return function clearup() {
      // console.log("===clear up");
    };
  }, [props, fullScreen]);

  // const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  const { src, height, width } = props;
  // console.log("item", height, width);
  const is_img_portrait = width / height < 1;
  const handle = useFullScreenHandle();

  const imgRef = useRef();

  const onClickHandler = () => {
    // const { onChanceFullScreenHandler } = props;
    set_click_to_change_screen(true);
    if (!fullScreen) {
      handle.enter();

      setFullScreen(true);
    } else {
      handle.exit();
      setFullScreen(false);
      // const { exit_slide_show } = props;
      // exit_slide_show();
      // setTimeout(() => {
      //   const img_left_offset =
      //     imgRef.current.offsetHeight > imgRef.current.offsetWidth
      //       ? imgRef.current.offsetWidth + "px"
      //       : "100%";
      //   set_img_left_offset(img_left_offset);
      // }, 400);
      // onChanceFullScreenHandler(false);
    }
    // setFullScreen(!fullScreen);
    // handle.enter()
  };

  useImperativeHandle(ref, () => ({
    zoomout() {
      // alert("getAlert from Child");
      setClickFromZoomIcon(true);
      onClickHandler();
    },
  }));

  const onImgLoad = ({ target: img }) => {
    // if (fullScreen) {
    //   setDimensions({
    //     height: img.offsetHeight,
    //     width: img.offsetWidth,
    //   });
    //   console.log("2121==", img.offsetHeight, img.offsetWidth);
    // }
    console.log("2121==", img.offsetHeight, img.offsetWidth);
    // const img_left_offset =
    //   img.offsetHeight > img.offsetWidth ? img.offsetWidth + "px" : "100%";
    // set_img_left_offset(img_left_offset);
    override_onImgLoad();
  };

  const override_onImgLoad = () => {
    const { image_width, image_height } = props.items_a;
    // if (fullScreen) {
    //   setDimensions({
    //     height: image_height,
    //     width: image_width,
    //   });
    //   console.log("2121==", image_height, image_width);
    // }
    const img_left_offset =
      image_height > image_width ? image_width + "px" : "100%";
    set_img_left_offset(img_left_offset);
  };

  const onChangeFullScreen = (isFullscreen) => {
    console.log("ecs....");
    const { onChanceFullScreenHandler } = props;
    onChanceFullScreenHandler(isFullscreen);
    console.log("ppppchange screen", isFullscreen);
    // setFullScreen(!fullScreen);
    if (!click_to_change_screen) {
      setFullScreen(false);
      // const { exit_slide_show } = props;
      // exit_slide_show();
    }
    set_click_to_change_screen(false);
  };

  const { nav_img_onclick } = props;
  // const { nav_img_onclick, onChanceFullScreenHandler } = props;
  // const img_height = Math.round(dimensions.height / 2) - 80;

  const movingmouse = (e) => {
    // if (iconsShow || !fullScreen) {
    if (!fullScreen) {
      return;
    }
    if (enterArrowArea) {
      return;
    }
    // if (fullScreen) {
    // console.log("moving", e.pageX);
    setIconsShow(true);
    if (movingTimer) {
      clearTimeout(movingTimer);
    }
    const moving_timer = setTimeout(() => {
      // console.log("Hello----");
      setIconsShow(false);
    }, 2000);

    setMovingTimer(moving_timer);
    // }
  };

  const enter_arrow_area = () => {
    console.log("mouse event: enter");
    const { exit_slide_show } = props;
    setEnterArrowArea(true);
    setIconsShow(true);
    if (movingTimer) {
      console.log("mouse event: enter 11");
      clearTimeout(movingTimer);
    }
    // if (fullScreen) {
    //   console.log("mouse event: enter 22");
    //   exit_slide_show();
    // }
    exit_slide_show();
  };

  const leave_arrow_area = () => {
    console.log("mouse event: leave");
    const { enter_slide_show } = props;
    setEnterArrowArea(false);
    // if (fullScreen) {
    //   if (!click_from_zoom_icon) {
    //     enter_slide_show();
    //   }
    // }
    if (!click_from_zoom_icon) {
      enter_slide_show();
    }
    // enter_slide_show();
  };

  // console.log("===333 img", props.index);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (props.is_mobile) {
        // console.log("prev");
        // alert("left");
        nav_img_onclick(1);
      }
    },

    onSwipedRight: () => {
      if (props.is_mobile) {
        // console.log("next");
        // alert("right");
        nav_img_onclick(0);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div onMouseMove={movingmouse}>
      <FullScreen
        className={iconsShow ? "" : "none-cursor"}
        enabled={fullScreen}
        // isEnter={true}
        handle={handle}
        // onChange={(isFullscreen) => onChanceFullScreenHandler(isFullscreen)}
        onChange={(isFullscreen) => {
          onChangeFullScreen(isFullscreen);

          if (!isFullscreen) {
            // console.log("onchange??", isFullscreen);
            const { exit_slide_show } = props;
            exit_slide_show();
          }
        }}
      >
        {!props.is_mobile && <span className="vertical-align-helper"></span>}
        <img
          ref={imgRef}
          src={src}
          // alt="gallery art main"
          alt={`gallery art main ${fullScreen ? "full-size-alt" : ""}`}
          // onClick={onClickHandler}
          width="100%"
          onLoad={onImgLoad}
          // className={"vertical-center odd tocolor-" + props.index}
          className={`vertical-center ${
            props.index % 2 === 0 ? "even" : "odd"
          }   ${is_img_portrait ? "img-portrait" : ""}`}
          // onMouseMove={movingmouse}
          style={{ "--mg_left_offset": img_left_offset }}
          {...handlers}
        />
        <div
          onMouseEnter={() => {
            enter_arrow_area();
          }}
          onMouseLeave={() => {
            leave_arrow_area();
          }}
          onClick={() => {
            console.log("back");
            nav_img_onclick(0);
          }}
          className={`nav-img left ${
            iconsShow ? "show-icons" : "no-show-icons"
          }`}
        >
          <span className="material-icons">arrow_back_ios</span>
        </div>
        <div
          onMouseEnter={() => {
            enter_arrow_area();
          }}
          onMouseLeave={() => {
            leave_arrow_area();
          }}
          onClick={() => {
            console.log("forward");
            nav_img_onclick(1);
          }}
          className={`nav-img right ${
            iconsShow ? "show-icons" : "no-show-icons"
          }`}
        >
          <span className="material-icons">arrow_forward_ios</span>
        </div>
        {/* <div className="slidershow-button">
            <button onClick={onClickHandler}>
              <span>Slideshow</span>
              <span className="material-icons">play_arrow</span>
            </button>
          </div> */}
        <div
          // className={`fullscreen-exit `}
          className={`fullscreen-exit ${
            iconsShow ? "show-icons" : "no-show-icons"
          }`}
          // style={{
          //   top: "calc(50% + " + img_height + "px)",
          // }}
          style={{
            top: "92%",
            right: "50px",
          }}
        >
          {/* <span className="material-icons" >
              fullscreen_exit
            </span> */}
          <img
            src={zoomin_img}
            alt="exit fullscreen"
            // onClick={onClickHandler}
            onClick={() => {
              const { set_fullscreen_message } = props;
              if (set_fullscreen_message) {
                set_fullscreen_message(false);
              }
              onClickHandler();
              // handle.exit();
              const { exit_slide_show } = props;
              exit_slide_show();
            }}
          />
        </div>
      </FullScreen>
    </div>
  );
});

const mapStateToProps = (state) => {
  return {
    // admin_login_failed: state.message.admin_login_failed,
    first_show_sliders: state.message.first_show_sliders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_first_show_slider_false: () =>
      dispatch(
        actions.set_message({
          name: "first_show_sliders",
          value: false,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PhotoArtTopImg);
