import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Card, CardImg, CardBody, CardSubtitle } from "reactstrap";

import * as actions from "../../../../store/actions/index";
import "./WritingsIndex.css";
import "../../../css/media/writings/writings-home.css";
import "../../../home/components/mobile/WritingsIndex.css";

import { BASE_URL } from "../../../../consts/const";

import Bio from "../../components/PhotoArtBio";
import MobilePhotoArtBio from "../../components/mobile/PhotoArtBio";

import book_img from "../../../../images/writings/book.png";
import Tools from "../../../../utils/tools";

class WritingsIndex extends Component {
  state = {
    is_visible: false,
  };

  componentDidMount() {
    // go to top start
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
    // go to top end
    // const { get_writings } = this.props;
    // get_writings();
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", () => {}, false);
  }

  create_comparator = (key) => {
    return (a, b) => {
      let val_a = a[key];
      let val_b = b[key];

      if (val_a < val_b) {
        return 1;
      }
      if (val_a > val_b) {
        return -1;
      }
      return 0;
    };
  };

  sortByID = () => {
    return (a, b) => {
      if (a.id === "1" && b.id !== "1") {
        return -1;
      }
      return 0;
    };
  };

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    const { is_visible } = this.state;
    const { writings, writing_categories } = this.props;
    const writings_1 = writings.filter((item) => {
      return item.delete !== "1" && item.category_id !== "1";
    });
    console.log("writings", writings_1, writing_categories);

    if (writings.length === 0) {
      return null;
    }
    const body_length = Tools.is_less_than(1200) ? 130 : 240;
    const title_length = Tools.is_less_than(1200) ? 30 : 60;
    console.log("text", body_length);

    const items = Tools.getUniqueArrayBy(writings_1, "category_id").filter(
      (writing) => {
        return (
          // writings.gallery === "1" &&
          writing.delete !== "1" && writing.category_id !== "1"
        );
      }
    );
    items.sort(this.create_comparator("category_display_id"));
    items.sort(this.sortByID());
    console.log("items", items);

    let desktop_html = (
      <>
        <Container className="home-container photo-art photo-art-display writing-section">
          <Row className="photo-art-row display-row writing-section">
            <Col lg="12" className="photo-art-col-display container">
              <div className="photo-art-main-image writing-section">
                <Row className="writing-section-first-div">
                  <Col lg="6" className="writings-text-div">
                    <div className="main-title">WRITINGS</div>
                  </Col>
                  <Col lg="6">
                    <img src={book_img} alt="book" />
                  </Col>
                </Row>
              </div>
              {/* <div className="photo-art-small-text-div">PHOTO ART</div>
            <div className="photo-art-large-text-div">PHOTO ART</div> */}
            </Col>
          </Row>

          <Row className="photo-art-row categories-row writing-section">
            <Col lg="12" className="container">
              <Row className="galleries-list-row">
                <Col lg="12" className="galleries-list">
                  <br />
                  <br />
                  <br />
                  {writings_1.slice(0, 5).map((writing) => {
                    const writing_title = writing.title;
                    const writing_body = Tools.parseMdForSummary(writing.body);
                    const writing_title_trim =
                      writing_title.length > title_length
                        ? writing_title.substring(0, title_length - 1) + "..."
                        : writing_title;

                    const writing_body_trim =
                      writing_body.length > body_length
                        ? writing_body.substring(0, body_length - 1) + "..."
                        : writing_body;

                    return (
                      <Row key={writing.id}>
                        <Col lg="3">
                          <img src={book_img} width="100%" alt="book_img" />
                          <img
                            src={`${BASE_URL}/cms-system/uploads/category_images/${writing.category_image_url}`}
                            width="25px"
                            className="writing-category-icon-overlap"
                            alt="book_img_icon"
                          />
                        </Col>
                        {/* {writing.category_image_url} */}
                        <Col lg="8">
                          <Link
                            to={`/writings/${writing.category_id}/${writing.id}`}
                          >
                            <div className="writing-title-div">
                              {writing_title_trim}
                            </div>
                            <div className="writing-publish-date-div">{`${new Date(
                              writing.publish_at
                            ).toDateString()}`}</div>
                            <div className="writing-body-div">
                              {writing_body_trim}
                            </div>
                          </Link>
                        </Col>
                      </Row>
                    );
                  })}
                  <br />
                  <br />
                  <br />
                  <div className="categories-title-div">Categories </div>
                  <Row>
                    {items.map((item) => {
                      return (
                        <Col lg="3">
                          <Link to={`/writings/${item.category_id}`}>
                            <Card>
                              <CardImg
                                top
                                width="100px"
                                height="100px"
                                src={`${BASE_URL}/cms-system/uploads/category_images/${item.category_image_url}`}
                                alt="Card image cap"
                              />
                              <CardBody>
                                <CardSubtitle tag="h6" className="text-muted">
                                  {item.category_name}
                                </CardSubtitle>
                              </CardBody>
                            </Card>
                          </Link>
                        </Col>
                      );
                    })}
                  </Row>
                  <br />
                  <br />
                </Col>
              </Row>
            </Col>
          </Row>

          {/* /// */}
        </Container>
        <Container className="home-container normal photo-art-bio">
          <br />
          <br />
          <br />
          <Bio />
        </Container>
      </>
    );

    let mobile_html = (
      <div id="mobile-photo-art-main-div" className="writings-section">
        <div className="margin-top-mobileview" />
        <div className="box-title">Writings</div>
        <div
          className="photo-art-main-image"
          style={{
            backgroundImage: `url(${book_img})`,
          }}
        >
          <div className="main-title">Writings</div>
        </div>
        <div className="galleries-section">
          {/* <div className="box-title">GALLERIES</div> */}
          {writings_1.slice(0, 5).map((writing) => {
            const writing_title = writing.title;
            const writing_body = Tools.parseMdForSummary(writing.body);
            const writing_title_trim =
              writing_title.length > title_length
                ? writing_title.substring(0, title_length - 1) + "..."
                : writing_title;

            const writing_body_trim =
              writing_body.length > body_length
                ? writing_body.substring(0, body_length - 1) + "..."
                : writing_body;

            return (
              <>
                <Link to={`/writings/${writing.category_id}/${writing.id}`}>
                  <Row className="writings-list-row">
                    <Col xs="12" className="writing-title">
                      {writing_title_trim}
                    </Col>
                  </Row>
                  <Row className="writings-list-row">
                    <Col xs="10" className="writing-date">
                      {`${new Date(writing.publish_at).toDateString()}`}
                    </Col>
                    <Col xs="1" className="writing-image">
                      <img
                        src={`${BASE_URL}/cms-system/uploads/category_images/${writing.category_image_url}`}
                        alt={writing.title}
                      />
                    </Col>
                  </Row>
                  <Row className="writings-list-row">
                    <Col xs="12" className="writing-body">
                      {writing_body_trim}
                    </Col>
                  </Row>
                </Link>
                <br />
              </>
            );
          })}

          <div className="box-title">Categories</div>
          <Row className="writing-category-list-row">
            {items.map((item) => {
              return (
                <Col sm="6" className="">
                  <Link to={`/writings/${item.category_id}`}>
                    <Card>
                      <CardImg
                        top
                        width="100px"
                        height="100px"
                        src={`${BASE_URL}/cms-system/uploads/category_images/${item.category_image_url}`}
                        alt="Card image cap"
                      />
                      <CardBody>
                        <CardSubtitle tag="h6" className="text-muted">
                          {item.category_name}
                        </CardSubtitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </div>
        <MobilePhotoArtBio />
        <div className="scroll-to-top">
          {is_visible && (
            <div onClick={() => this.scrollToTop()}>
              <span className="material-icons">expand_circle_down</span>
            </div>
          )}
        </div>
      </div>
    );

    if (!Tools.is_less_than(1000)) {
      return <>{desktop_html}</>;
    } else {
      return <>{mobile_html}</>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    writings: state.writing.writings,
    writing_categories: state.writing_categories.writing_categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_writings: () => dispatch(actions.get_writings()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WritingsIndex);
