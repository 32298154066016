import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";

import * as actions from "../../store/actions/index";
import "./Login.css";
import { BASE_URL } from "../../consts/const";

class ResetPassword extends React.Component {
  state = {
    email: "",
    password: "",
    captcha: "",
    sign_in_disable: true,
    captcha_disable: false,
    random_number: "",
  };

  componentDidMount() {
    const { init_admin_get_code_status } = this.props;
    init_admin_get_code_status();
    this.setState({
      random_number: Math.floor(Math.random() * 1000000) + 1,
    });
  }

  onChangeHandler = (e) => {
    // console.log(e.target);
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onChangeCaptchaHandler = (e) => {
    const { random_number } = this.state;
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      async () => {
        // console.log(this.state);

        if (value.length < 6) {
          return;
        }
        const header = {
          headers: {
            "Content-Type": "application/ix-www-form-urlencoded",
          },
        };
        const res = await axios.post(
          `${BASE_URL}/cms-system/api/captcha/check.php`,
          {
            captcha_code: value,
            rand_num: random_number,
          },
          header
        );
        if (res.data) {
          this.setState({
            sign_in_disable: false,
            captcha_disable: true,
          });
        } else {
          console.log("false222");
        }
      }
    );
  };

  // loginHandler = async (event) => {
  //   event.preventDefault();
  //   const { username, password } = this.state;
  //   const { login } = this.props;

  //   const payload = {
  //     name: username,
  //     password,
  //   };
  //   login(payload);
  // };

  sendCodeHandler = async (event) => {
    event.preventDefault();
    const { email } = this.state;
    const { get_code } = this.props;

    const payload = {
      email,
    };
    get_code(payload);
  };

  render() {
    const {
      username,
      captcha,
      sign_in_disable,
      captcha_disable,
      random_number,
      email,
    } = this.state;
    const { admin_get_code_success, admin_get_code_failed } = this.props;
    console.log("admin_get_code_success", admin_get_code_success);
    return (
      <>
        {email && admin_get_code_success && (
          <Redirect to={`/admin/resetpassword2?email=${email}`} />
        )}
        <div className="login-main-div">
          <Col sm={12} className="sign-in-header">
            Password Reset
          </Col>
          <Form>
            <FormGroup>
              <Label for="username" sm={4}>
                Email Address
              </Label>
              <Col sm={12}>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  // placeholder="Enter email"
                  value={username}
                  onChange={this.onChangeHandler}
                />
              </Col>
            </FormGroup>

            <FormGroup className="captcha">
              <Label for="username" sm={4}>
                Enter the code
              </Label>
              <Row>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="captcha"
                    id="captcha"
                    // placeholder="Enter captcha code"
                    value={captcha}
                    maxLength={6}
                    disabled={captcha_disable}
                    onChange={this.onChangeCaptchaHandler}
                  />
                </Col>
                <Col sm={6} className="captcha-img">
                  <img
                    src={`${BASE_URL}/cms-system/api/captcha/captcha.php?rand=${random_number}`}
                    id="captchaimg"
                    width="230px"
                    height="60px"
                    alt="captcha"
                  />
                </Col>
              </Row>
            </FormGroup>
            <br />
            <FormGroup>
              <Col sm={12}>
                <Button
                  // outline
                  color="secondary"
                  type="submit"
                  onClick={this.sendCodeHandler}
                  disabled={sign_in_disable}
                >
                  Send Instructions
                </Button>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={12}>
                <Link to="/admin/login">Sign in</Link>
              </Col>
            </FormGroup>
            <Col sm={12}>
              {!!admin_get_code_failed && (
                <Alert color="warning">{admin_get_code_failed}</Alert>
              )}
            </Col>
          </Form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // admin_login_failed: state.message.admin_login_failed,
    admin_get_code_success: state.message.admin_get_code_success,
    admin_get_code_failed: state.message.admin_get_code_failed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init_admin_get_code_status: () =>
      dispatch(
        actions.set_message({
          name: "admin_get_code_success",
          value: null,
        })
      ),
    get_code: (payload) => dispatch(actions.get_code(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
