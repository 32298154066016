import * as actionTypes from "./actionTypes";

// new
export const is_auth = () => {
  console.log("is_auth action ");
  return {
    type: actionTypes.IS_AUTH,
  };
};

export const set_auth = (payload) => {
  console.log("set auth action");
  return {
    type: actionTypes.SET_AUTH,
    payload,
  };
};

export const logout = () => {
  // console.log("history,", history);
  return {
    type: actionTypes.LOGOUT,
  };
};

export const login = (payload) => {
  // payload : name : password
  console.log("login action", payload);
  return {
    type: actionTypes.LOGIN,
    payload,
  };
};

export const get_code = (payload) => {
  // payload : email
  console.log("get_code action", payload);
  return {
    type: actionTypes.GET_CODE,
    payload,
  };
};

export const reset_password = (payload) => {
  // payload : email, code , password
  console.log("reset_password action", payload);
  return {
    type: actionTypes.RESET_PASSWORD,
    payload,
  };
};
