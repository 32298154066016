import axios from "axios";
import { put } from "redux-saga/effects";

import { BASE_URL } from "../../consts/const";
import * as actions from "../actions/index";

export function* getpostsSaga(action) {
  console.log("i am getpostsSaga", action);

  try {
    // const token = localStorage.getItem("token");
    const header = {
      headers: {
        "Content-Type": "application/ix-www-form-urlencoded",
        // Authorization: `Bearer ${token || ""}`,
      },
    };

    const response = yield axios.get(
      `${BASE_URL}/cms-system/api/post/read.php`,
      header
    );
    // console.log("response get", response);
    if (Array.isArray(response.data)) {
      yield put(actions.set_posts(response.data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deletePostSaga(action) {
  console.log("i am deletePostSaga", action);
  const { payload } = action;
  try {
    const token = localStorage.getItem("token");
    const header = {
      headers: {
        "Content-Type": "application/ix-www-form-urlencoded",
        Authorization: `Bearer ${token || ""}`,
      },
    };
    const response = yield axios.post(
      `${BASE_URL}/cms-system/api/post/delete.php`,
      payload,
      header
    );
    yield put(actions.get_posts());
    console.log("response delete post", response);
  } catch (err) {
    console.log(err);
  }
}

export function* addPostSaga(action) {
  console.log("i am addPostSaga", action);
  const { payload } = action;
  console.log("payload", payload);
  const { file, post } = action.payload;
  console.log(file, post);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/ix-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  yield put(
    actions.set_message({
      name: "admin_add_post_success",
      value: false,
    })
  );

  yield put(
    actions.set_message({
      name: "admin_add_post_failed",
      value: null,
    })
  );

  try {
    const formData = new FormData();
    formData.append("upload", "upload");

    formData.append("file", file);

    console.log("formData", formData);
    let response = yield axios.post(
      `${BASE_URL}/cms-system/api/post/upload.php`,
      formData,
      header
    );

    const { data, status } = response;
    if (status === 201) {
      console.log("response----", response.status, data);
      const test = data.split("|||||");
      console.log("response----2", test[1]);

      post.url = test[1];
      response = yield axios.post(
        `${BASE_URL}/cms-system/api/post/create.php`,
        // payload,
        post,
        header
      );
      console.log("response create post", response);
      yield put(
        actions.set_message({
          name: "admin_add_post_success",
          value: true,
        })
      );
    }
  } catch (err) {
    // console.log("err==1", err.status);
    yield put(
      actions.set_message({
        name: "admin_add_post_failed",
        value: "Upload image failed, please try again later",
      })
    );
  }
}

export function* updatePostSaga(action) {
  console.log("i am updatePostSaga", action);
  const { payload, file } = action;
  console.log("payload, file", payload, file);

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      "Content-Type": "application/ix-www-form-urlencoded",
      Authorization: `Bearer ${token || ""}`,
    },
  };

  yield put(
    actions.set_message({
      name: "admin_update_post_success",
      value: false,
    })
  );

  yield put(
    actions.set_message({
      name: "admin_update_post_failed",
      value: null,
    })
  );

  try {
    let response;
    if (file) {
      console.log("file", file);

      const formData = new FormData();
      formData.append("upload", "upload");

      formData.append("file", file);

      console.log("formData", formData);
      response = yield axios.post(
        `${BASE_URL}/cms-system/api/post/upload.php`,
        formData,
        header
      );

      const { data, status } = response;
      if (status === 201) {
        const test = data.split("|||||");
        console.log("response----", response.status, data, file.size);
        console.log("response----2", test[1]);
        payload.old_url = payload.url;
        payload.size = file.size;
        payload.url = test[1];
      }
    }

    response = yield axios.post(
      `${BASE_URL}/cms-system/api/post/update.php`,
      payload,
      header
    );

    if (payload.home === 1) {
      response = yield axios.post(
        `${BASE_URL}/cms-system/api/post/reset_home.php`,
        payload,
        header
      );
    }
    if (payload.gallery === 1) {
      response = yield axios.post(
        `${BASE_URL}/cms-system/api/post/reset_category.php`,
        payload,
        header
      );
    }

    yield put(actions.get_posts());
    yield put(
      actions.set_message({
        name: "admin_update_post_success",
        value: true,
      })
    );
    console.log("response update post", response);
  } catch (err) {
    console.log(err);
    yield put(
      actions.set_message({
        name: "admin_update_post_failed",
        value: true,
      })
    );
  }
}

export function* reorderPostsSaga(action) {
  console.log("i am reorderPostsSaga", action);
  const { payload } = action;
  yield put(
    actions.set_message({
      name: "admin_updating_image_order",
      value: "loading",
    })
  );
  try {
    const token = localStorage.getItem("token");
    const header = {
      headers: {
        "Content-Type": "application/ix-www-form-urlencoded",
        Authorization: `Bearer ${token || ""}`,
      },
    };
    const response = yield axios.post(
      `${BASE_URL}/cms-system/api/post_order/reorder.php`,
      payload,
      header
    );
    yield put(actions.get_posts());
    console.log("response reorderPostsSaga", response);
    yield put(
      actions.set_message({
        name: "admin_updating_image_order",
        value: "success",
      })
    );
  } catch (err) {
    console.log(err);
    yield put(
      actions.set_message({
        name: "admin_updating_image_order",
        value: "failure",
      })
    );
  }
}
