import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./ShowImages.css";
import {
  Button,
  FormGroup,
  // Label,
  Input,
  // Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { BASE_URL } from "../../consts/const";
import * as actions from "../../store/actions/index";

class ShowImages extends React.Component {
  state = {
    delete_post: null,
    modal: false,
    map: new Map(),
    sortBy: null,
    reverse: true,
  };
  componentDidMount() {
    console.log(this.props);

    const { get_posts } = this.props;
    get_posts();
  }

  deleteImage = async (id, url) => {
    console.log("id", id);

    const payload = {
      id,
      url,
    };
    const { delete_post } = this.props;
    delete_post(payload);
  };

  onChangeHomeSubmit = (post) => {
    console.log("post", post);
    post.home = post.home === "1" ? 0 : 1;
    const { update_post } = this.props;
    update_post(post);
  };

  onChangeGallerySubmit = (post) => {
    console.log("post", post);
    post.gallery = post.gallery === "1" ? 0 : 1;
    const { update_post } = this.props;
    update_post(post);
  };

  // onChangeDeleteSubmit = (post) => {
  //   console.log("post", post);
  //   post.delete = post.delete === "1" ? 0 : 1;
  //   const { update_post } = this.props;
  //   update_post(post);
  // };

  onClickDelete = (post) => {
    this.setState({
      delete_post: post,
      modal: true,
    });
  };

  onClickDeleteConfirm = () => {
    const { delete_post } = this.state;
    delete_post.delete = delete_post.delete === "1" ? 0 : 1;
    const { update_post } = this.props;
    update_post(delete_post);
    this.setState({
      delete_post: null,
      modal: false,
    });
  };

  onClickDeleteCancel = () => {
    this.setState({
      delete_post: null,
      modal: false,
    });
  };

  onClickEdit = (post) => {
    // console.log("edit", this.props);
    const { push } = this.props.history;
    push({
      pathname: "/admin/post/update",
      post,
    });
  };
  onImgLoad = ({ target: img }) => {
    // console.log("img loaded", img);
    // console.log("img loaded", img.offsetWidth, img.offsetHeight, img.id);
    const checkable = img.offsetWidth / img.offsetHeight > 1 ? true : false;
    const { map } = this.state;
    map.set(img.id, checkable);
    this.setState({
      [img.id]: checkable,
    });
  };

  onClickSortHandler = (sortBy) => {
    this.setState((prevState) => {
      return {
        // ...prevState,
        sortBy,
        reverse: !prevState.reverse,
      };
    });
    console.log("sortBy", sortBy);
  };

  create_comparator = (key) => {
    const { reverse } = this.state;
    return (a, b) => {
      let val_a = a[key];
      let val_b = b[key];
      if (key === "size") {
        val_a = Number(a[key]);
        val_b = Number(b[key]);
      }
      if (val_a < val_b) {
        return !reverse ? -1 : 1;
      }
      if (val_a > val_b) {
        return !reverse ? 1 : -1;
      }
      return 0;
    };
  };

  render() {
    const { posts } = this.props;
    const {
      //  map,
      sortBy,
    } = this.state;

    const total = posts.filter((post) => post.delete !== "1").length;
    const published_total = posts
      .filter((post) => post.delete !== "1")
      .filter((post) => post.category_id !== "1")
      .filter((post) => post.location_id !== "1").length;

    //
    console.log("posts abc", posts, total, published_total);
    let sorted_posts = [...posts];

    if (sortBy) {
      sorted_posts.sort(this.create_comparator(sortBy));
    } else {
      sorted_posts.sort(this.create_comparator("modified_at"));
    }

    return (
      <div className="admin-images-list-main">
        <div className="admin-header">
          Images{" "}
          <span className="total-detail">
            Total: {total} / published: {published_total}
          </span>
        </div>

        <div className="float-right">
          <Link to="/admin/post/add">
            {" "}
            <span>+</span> Upload new Image
          </Link>
        </div>

        <Row className="mt-5" id="admin-list-images-row">
          <Col xl="2" className="admin-list-image-col col-header"></Col>
          <Col xl="2" className="admin-list-image-col col-header">
            Image Name
            <span
              className="material-icons"
              onClick={() => this.onClickSortHandler("title")}
            >
              expand_more
            </span>
            {/* <span className="material-icons">expand_less</span> */}
          </Col>
          <Col xl="1" className="admin-list-image-col col-header">
            Location
            <span
              className="material-icons"
              onClick={() => this.onClickSortHandler("location_name")}
            >
              expand_more
            </span>
          </Col>
          <Col xl="1" className="admin-list-image-col col-header">
            Gallery
            <span
              className="material-icons"
              onClick={() => this.onClickSortHandler("category_name")}
            >
              expand_more
            </span>
          </Col>
          <Col xl="1" className="admin-list-image-col col-header">
            Size
            <span
              className="material-icons"
              onClick={() => this.onClickSortHandler("size")}
            >
              expand_more
            </span>
          </Col>
          <Col xl="1" className="admin-list-image-col col-header">
            Modified
            <span
              className="material-icons"
              onClick={() => this.onClickSortHandler("modified_at")}
            >
              expand_more
            </span>
          </Col>
          <Col xl="1" className="admin-list-image-col col-header check">
            Home
            <span
              className="material-icons"
              onClick={() => this.onClickSortHandler("home")}
            >
              expand_more
            </span>
          </Col>
          <Col xl="1" className="admin-list-image-col col-header check">
            Gallery
            <span
              className="material-icons"
              onClick={() => this.onClickSortHandler("gallery")}
            >
              expand_more
            </span>
          </Col>
          <Col xl="1" className="admin-list-image-col col-header"></Col>
          <Col xl="1" className="admin-list-image-col col-header"></Col>

          <Col xl="12">
            <hr />
          </Col>
          {sorted_posts
            .filter((post) => {
              return post.delete === "0";
            })
            .map((post) => {
              return (
                <React.Fragment key={post.url}>
                  <Col xl="2" className="admin-list-image-col">
                    <img
                      src={`${BASE_URL}/cms-system/uploads/${post.url}`}
                      width={"130px"}
                      alt={post.title}
                      onLoad={this.onImgLoad}
                      id={post.id}
                      onClick={() => this.onClickEdit(post)}
                    />
                  </Col>
                  <Col xl="2" className="admin-list-image-col">
                    {post.title}
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    {post.location_name}
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    {post.category_name}
                  </Col>
                  <Col xl="1" className="admin-list-image-col check">
                    {post.size >= 1000000 &&
                      (post.size / 1000000).toFixed(0) + "Mb"}
                    {post.size < 1000000 &&
                      (post.size / 1000).toFixed(0) + "Kb"}
                  </Col>
                  <Col xl="1" className="admin-list-image-col no-wrop">
                    {post.modified_at.split(" ")[0]}
                  </Col>
                  <Col xl="1" className="admin-list-image-col check">
                    <FormGroup check>
                      <Input
                        id={`home-${post.id}`}
                        type="checkbox"
                        checked={post.home !== "0"}
                        onChange={() => this.onChangeHomeSubmit(post)}
                        // disabled={!map.get(post.id)}
                        disabled={!this.state[post.id]}
                      />
                      <label
                        // for={`home-${post.id}`}
                        htmlFor={`home-${post.id}`}
                        id="certify_label"
                      ></label>
                    </FormGroup>
                  </Col>
                  <Col xl="1" className="admin-list-image-col check">
                    <FormGroup check>
                      <Input
                        id={`gallery-${post.id}`}
                        type="checkbox"
                        checked={post.gallery !== "0"}
                        onChange={() => this.onChangeGallerySubmit(post)}
                        // disabled={!map.get(post.id)}
                      />
                      <label
                        // for={`gallery-${post.id}`}
                        htmlFor={`gallery-${post.id}`}
                        id="certify_label"
                      ></label>
                    </FormGroup>
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <Button
                      color="link"
                      className="link-button"
                      onClick={() => this.onClickEdit(post)}
                    >
                      Edit
                    </Button>
                  </Col>
                  <Col xl="1" className="admin-list-image-col">
                    <Button
                      color="link"
                      className="link-button"
                      // onClick={() => this.onChangeDeleteSubmit(post)}
                      onClick={() => this.onClickDelete(post)}
                    >
                      Delete
                    </Button>
                  </Col>
                </React.Fragment>
              );
            })}
        </Row>
        <Modal isOpen={this.state.modal}>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalBody>Are you sure?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onClickDeleteConfirm}>
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.onClickDeleteCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {/* <div className="row mt-5">
          {posts.map((post) => (
            <div className="col aa" key={post.url}>
              <div>
                <h5>Title: {post.title}</h5>
              </div>
              <div>
                <p>Description: {post.body}</p>
              </div>
              <div>
                <p>Category: {post.category_name}</p>
              </div>
              <div>
                <p>Location: {post.location_name}</p>
              </div>
              <div>
                <p>Update at: {post.modified_at}</p>
              </div>
              <div>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={post.home !== "0"}
                      onChange={() => this.onChangeHomeSubmit(post)}
                    />{" "}
                    Home
                  </Label>
                </FormGroup>
              </div>

              <div>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={post.gallery !== "0"}
                      onChange={() => this.onChangeGallerySubmit(post)}
                    />{" "}
                    Gallery
                  </Label>
                </FormGroup>
              </div>

              <img
                src={`${BASE_URL}/cms-system/uploads/${post.url}`}
                width={"280px"}
                alt={post.title}
              />
              <div className="mt-3">
                <Button
                  outline
                  color="danger"
                  onClick={() => this.deleteImage(post.id, post.url)}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}
        </div> */}
        {posts.length === 0 && <div>No image found</div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.post.posts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_posts: () => dispatch(actions.get_posts()),
    delete_post: (payload) => dispatch(actions.delete_post(payload)),
    update_post: (payload) => dispatch(actions.update_post(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowImages);
