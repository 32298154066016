import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";

import * as actions from "../../store/actions/index";
import "./Login.css";
import { BASE_URL } from "../../consts/const";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    code: "",
    captcha: "",
    sign_in_disable: true,
    captcha_disable: false,
    random_number: "",
  };

  componentDidMount() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    console.log(email);
    this.setState({
      random_number: Math.floor(Math.random() * 1000000) + 1,
      email,
    });
  }

  onChangeHandler = (e) => {
    // console.log(e.target);
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onChangeCaptchaHandler = (e) => {
    const { random_number } = this.state;
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      async () => {
        // console.log(this.state);

        if (value.length < 6) {
          return;
        }
        const header = {
          headers: {
            "Content-Type": "application/ix-www-form-urlencoded",
          },
        };
        const res = await axios.post(
          `${BASE_URL}/cms-system/api/captcha/check.php`,
          {
            captcha_code: value,
            rand_num: random_number,
          },
          header
        );
        if (res.data) {
          this.setState({
            sign_in_disable: false,
            captcha_disable: true,
          });
        } else {
          console.log("false222");
        }
      }
    );
  };

  //   loginHandler = async (event) => {
  //     event.preventDefault();
  //     const { username, password } = this.state;
  //     const { login } = this.props;

  //     const payload = {
  //       name: username,
  //       password,
  //     };
  //     login(payload);
  //   };

  resetPasswordHandler = (event) => {
    event.preventDefault();
    const { email, password, code } = this.state;
    console.log(email, password, code);
    // return;
    const { reset_password } = this.props;

    const payload = {
      email,
      code,
      password,
    };
    reset_password(payload);
  };

  render() {
    const {
      email,
      password,
      code,
      captcha,
      sign_in_disable,
      captcha_disable,
      random_number,
    } = this.state;
    const {
      admin_reset_password_success,
      admin_reset_password_failed,
    } = this.props;
    return (
      <div className="login-main-div">
        <Col sm={12} className="sign-in-header">
          Reset password
        </Col>
        <Form>
          <FormGroup>
            <Label for="username" sm={4}>
              Email Address
            </Label>
            <Col sm={12}>
              <Input
                type="text"
                name="username"
                id="username"
                // placeholder="Enter username"
                value={email}
                disabled
              />
            </Col>
          </FormGroup>

          <FormGroup>
            <Label for="password" sm={4}>
              New password
            </Label>
            <Col sm={12}>
              <Input
                type="password"
                name="password"
                id="password"
                // placeholder="Enter password"
                value={password}
                // defaultValue=""
                onChange={this.onChangeHandler}
              />
            </Col>
          </FormGroup>

          <FormGroup>
            <Label for="password" sm={8}>
              Verify code from email
            </Label>
            <Col sm={12}>
              <Input
                type="text"
                name="code"
                id="code"
                // placeholder="Enter verify code"
                value={code}
                // defaultValue=""
                onChange={this.onChangeHandler}
              />
            </Col>
          </FormGroup>

          <FormGroup className="captcha">
            <Label for="username" sm={4}>
              Enter the code
            </Label>
            <Row>
              <Col sm={5}>
                <Input
                  type="text"
                  name="captcha"
                  id="captcha"
                  // placeholder="Enter captcha code"
                  value={captcha}
                  maxLength={6}
                  disabled={captcha_disable}
                  onChange={this.onChangeCaptchaHandler}
                />
              </Col>
              <Col sm={6} className="captcha-img">
                <img
                  src={`${BASE_URL}/cms-system/api/captcha/captcha.php?rand=${random_number}`}
                  id="captchaimg"
                  width="230px"
                  height="60px"
                  alt="captcha"
                />
              </Col>
            </Row>
          </FormGroup>
          <br />
          <FormGroup>
            <Col sm={12}>
              <Button
                // outline
                color="secondary"
                type="submit"
                // onClick={this.loginHandler}
                onClick={this.resetPasswordHandler}
                disabled={sign_in_disable}
              >
                Reset
              </Button>
            </Col>
          </FormGroup>

          <Col sm={12}>
            {!!admin_reset_password_success && (
              <Alert color="success">
                Password reset, please sign in with new password
              </Alert>
            )}
          </Col>
          <Col sm={12}>
            {!!admin_reset_password_failed && (
              <Alert color="warning">{admin_reset_password_failed}</Alert>
            )}
          </Col>
          <FormGroup>
            <Col sm={12}>
              <Link to="/admin/login">Sign in</Link>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admin_reset_password_failed: state.message.admin_reset_password_failed,
    admin_reset_password_success: state.message.admin_reset_password_success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init_admin_reset_password_status: () =>
      dispatch(
        actions.set_message({
          name: "admin_reset_password_success",
          value: null,
        })
      ),
    reset_password: (payload) => dispatch(actions.reset_password(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
