import * as actionTypes from "../actions/actionTypes";

const initialState = {
  writing_categories: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_WRITING_CATEGORIES:
      console.log("set writing categories reducer", action);
      const { payload } = action;
      return {
        ...state,
        writing_categories: payload,
      };
    default:
      return state;
  }
};

export default reducer;
