import React, { Component } from "react";
import { Container, Row, Col, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import // BrowserView,
// MobileView,
// isTablet,
// isBrowser,
// isMobile,
"react-device-detect";

// import PhotoArtTopImg from "./components/PhotoArtTopImg";
import PhotoArtTopImg from "../components/PhotoArtTopImg";
// import { BASE_URL } from "../../consts/const";
import { BASE_URL } from "../../../consts/const";

// import NewSlideShowImage from "./NewSlideShowImage";
import withForwardedRef from "./withForwardRef";
import "./NewSlideShow.css";
import "../../../components/css/media/new-slide-show.css";

import Tools from "../../../utils/tools";
// import * as actions from "../../../store/actions/index";

class NewSlideShow extends Component {
  state = {
    home_index: null,
    items: [],
    index: 0,
    fullScreenState: false,
    is_landscape: false,
    is_desktop: true,
    left: false,
    right: false,
  };
  navRef = React.createRef();

  componentDidMount() {
    document.body.classList.add("home-body-without-minheight");
    this.setState({
      is_landscape: this.isLandscape(),
    });
    window.addEventListener("resize", this.resize_fn);
    // window.addEventListener("resize", () => {
    //   this.setState({
    //     is_landscape: this.isLandscape(),
    //   });
    //   if (this.navRef.current) {
    //     this.navRef.current.scrollLeft = 0;
    //     this.set_arrow_state();
    //   }
    // });

    this.setState({
      is_desktop: !Tools.is_less_than(1200),
    });
    // window.addEventListener("resize", () => {
    //   // console.log(Tools.GetWindowWidth());
    //   const { fullscreen_message } = this.props;
    //   if (fullscreen_message) return;
    //   this.setState({
    //     is_desktop: !Tools.is_less_than(1200),
    //   });
    // });

    // console.log("ddd check", this.props);
    // console.log("===444slide show");
    // const { get_posts } = this.props;
    // get_posts();
    window.scrollTo(0, 0);
    document.addEventListener("keydown", this.moveImageByKeyFunc, false);

    // const { location } = this.props;
    // const params = new URLSearchParams(location.search);
    // const index = +params.get("index");
    // this.setState({
    //   home_index: this.props.index,
    // });
    this.navRef.current.scrollLeft = 0;
    this.set_arrow_state();
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.moveImageByKeyFunc, false);
    // document.removeEventListener("resize", () => {}, false);
    document.removeEventListener("resize", this.resize_fn);
    // if (this.state.intervalId) {
    //   clearInterval(this.state.intervalId);
    // }
    this.exit_slide_show();
  }

  resize_fn = () => {
    this.setState({
      is_landscape: this.isLandscape(),
    });
    if (this.navRef.current) {
      this.navRef.current.scrollLeft = 0;
      this.set_arrow_state();
    }
    const { fullscreen_message } = this.props;
    if (fullscreen_message) return;
    this.setState({
      is_desktop: !Tools.is_less_than(1200),
    });
  };

  isLandscape() {
    const { innerWidth: width, innerHeight: height } = window;
    return width / height > 1;
  }

  set_arrow_state = () => {
    const { scrollLeft, scrollWidth, offsetWidth } = this.navRef.current;
    console.log("arrow state", scrollLeft, scrollWidth, offsetWidth);
    this.setState({
      left: scrollLeft > 0,
      right: scrollWidth !== offsetWidth + scrollLeft,
    });
  };
  handleNav = (direction) => {
    if (direction === "left") {
      console.log(
        "left",
        this.navRef.current.scrollLeft,
        this.navRef,
        this.navRef.current.scrollWidth,
        this.navRef.current.offsetWidth
      );
      this.navRef.current.scrollLeft =
        this.navRef && this.navRef.current
          ? (this.navRef.current.scrollLeft -= 80)
          : null;
      this.set_arrow_state();
    } else {
      console.log(
        "right",
        this.navRef.current.scrollLeft,
        this.navRef,
        this.navRef.current.scrollWidth,
        this.navRef.current.offsetWidth
      );
      this.navRef.current.scrollLeft =
        this.navRef && this.navRef.current
          ? (this.navRef.current.scrollLeft += 80)
          : null;
      this.set_arrow_state();
    }
  };

  exit_slide_show = () => {
    // console.log("exit_slide_show");
    const { set_fullscreen_message } = this.props;
    set_fullscreen_message(false);
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  };
  enter_slide_show = () => {
    const { onClickImage } = this.props;
    const intervalId = setInterval(() => {
      // this.onClickImage(1);
      onClickImage(1);
    }, 5000);
    this.setState({ intervalId: intervalId });
  };

  moveImageByKeyFunc = (event) => {
    const { onClickImage } = this.props;
    if (event.keyCode === 37 && this.state.fullScreenState) {
      // console.log(event.keyCode);
      // this.onClickImage(0);
      onClickImage(0);
    }
    if (event.keyCode === 39 && this.state.fullScreenState) {
      // console.log(event.keyCode);
      // this.onClickImage(1);
      onClickImage(1);
    }
  };
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    // if (this.props.userID !== prevProps.userID) {
    //   this.fetchData(this.props.userID);
    // }
    if (
      this.props.home_index !== prevProps.home_index ||
      this.props.isGallery !== prevProps.isGallery
    ) {
      setTimeout(() => {
        if (this.navRef.current) {
          this.navRef.current.scrollLeft = 0;
          this.set_arrow_state();
        }
      }, 12);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.cat_id !== this.props.cat_id) {
      console.log("change props", this.props, nextProps);
      const { posts } = nextProps;
      // const { location } = this.props;
      // const params = new URLSearchParams(location.search);
      // const cat_id = params.get("id");
      const cat_id = nextProps.cat_id;

      const items = posts.filter((post) => {
        return post.category_id === cat_id && post.delete !== "1";
      });

      const home_post_index = items.findIndex((post) => {
        return post.category_id === cat_id && post.gallery === "1";
      });
      console.log("okok", home_post_index, cat_id, this.state, nextState);
      this.setState({
        // home_index: home_post_index,
        items,
      });
    }
    return true;
  }

  // if 0, move left, else move right
  // retire this method, move up to its parent
  onClickImage = (left_or_right) => {
    console.log("onclick", this.state);
    // console.log("left or right", left_or_right);
    this.setState((state) => {
      let new_index;
      const items_length = state.items.length;
      if (left_or_right === 0) {
        new_index = state.home_index - 1;
        if (new_index < 0) {
          new_index = items_length - 1;
        }
      } else {
        new_index = state.home_index + 1;
        // console.log("new_index", new_index);
        if (new_index >= items_length) {
          new_index = new_index % items_length;
        }
      }

      return { home_index: new_index };
    });
  };

  onChangeFullScreenState = (fullScreenState) => {
    this.setState({
      fullScreenState,
    });
  };

  child = React.createRef();
  onClick_zoomout = () => {
    this.child.current.zoomout();
    // console.log("this", this.child);
  };

  find_index_by_id = (posts, id) => {
    const idx = posts.findIndex((post) => post.id === id);
    console.log("p_id 123 abd", idx);
    return idx;
  };

  render() {
    // console.log("my this props", this.props);
    const { forwardedRef, isGallery } = this.props;
    // console.log("forwardedRef", forwardedRef, isGallery);
    //=====================
    const { posts, items, cat_id, onClickImage } = this.props;
    let home_index = this.props.home_index;
    const { left, right } = this.state;
    // const {
    //   //  items, home_index
    // } = this.state;
    // console.log("uniq", items, posts, home_index);
    // console.log("check posts items", posts, items, home_index);
    if (posts.length === 0 || items.length === 0) {
      return null;
    }
    if (home_index === null || home_index === undefined) {
      return null;
    }

    const { mylocation } = this.props;
    const params = new URLSearchParams(mylocation.search);
    const p_id = params.get("pid");
    console.log("p_id 123", items, p_id);
    const idx = this.find_index_by_id(items, p_id);
    if (idx >= 0) {
      console.log("p_id 123 nothing", idx);
      home_index = idx;
    }

    const is_image_portrait =
      items[home_index].height / items[home_index].width > 1;
    const { is_desktop } = this.state;
    let slide_show_html = is_desktop ? (
      <Container className="home-container normal">
        <Row className="gallery-row display-row slide-show">
          <Col
            xl="8"
            className="container img-container desktop-slide-show-container"
          >
            <PhotoArtTopImg
              src={`${BASE_URL}/cms-system/uploads/${items[home_index].url}`}
              nav_img_onclick={onClickImage}
              onChanceFullScreenHandler={this.onChangeFullScreenState}
              enlarge_first={this.state.large}
              exit_slide_show={this.exit_slide_show}
              enter_slide_show={this.enter_slide_show}
              index={home_index}
              items_a={items[home_index]}
              ref={isGallery ? forwardedRef : this.child}
            />
          </Col>
          <Col xl="1"></Col>
          <Col xl="3" className="container desc ">
            <div className="left-desc-div vertical-center left-desc-div-under-1199">
              <div className="gallery-slide-show-title">
                {items[home_index].title}
              </div>
              <div className="gallery-slide-show-content-and-location-div">
                <div className="gallery-slide-show-content">
                  {items[home_index].body}
                </div>
                <div className="gallery-slide-show-location">
                  {items[home_index].location_name}
                </div>
              </div>
              {/* <div className="tags-group-div">33
                <div className="tags-group-sub-div"> */}
              <div className="menu-container">
                <div className="arrow-div">
                  {left && (
                    <span
                      onClick={() => this.handleNav("left")}
                      className="material-icons left"
                    >
                      chevron_left
                    </span>
                  )}
                </div>
                <div className="nav-items" ref={this.navRef}>
                  {items[home_index].tags
                    .split(",")
                    .map((tag) => tag.trim())
                    .filter((tag) => tag !== "")
                    .map((tag, idx) => {
                      return (
                        <Badge key={idx} color="secondary">
                          {tag}
                        </Badge>
                      );
                    })}
                </div>
                <div className="arrow-div">
                  {right && (
                    <span
                      onClick={() => this.handleNav("right")}
                      className="material-icons right"
                    >
                      chevron_right
                    </span>
                  )}
                </div>
              </div>
              {/* <Badge color="secondary" className="invisible">
                    {"l"}
                  </Badge> */}
              {/* </div>
              </div> */}
              <div className="row gallery-slide-show-div">
                <div className="col-md-3">
                  <span
                    className="material-icons"
                    onClick={() => onClickImage(0)}
                  >
                    arrow_back_ios
                  </span>
                </div>
                <div className="col-md-3">
                  <span
                    className="material-icons"
                    onClick={() => onClickImage(1)}
                  >
                    arrow_forward_ios
                  </span>
                </div>
                <div
                  className="col-md-3"
                  onClick={this.props.go_back_to_gallery}
                >
                  <Link to={`/photography/gallery?id=${cat_id}`}>
                    <span className="material-icons">reply</span>
                  </Link>
                </div>
                <div className="col-md-3">
                  <span
                    className="material-icons"
                    onClick={() => {
                      this.onClick_zoomout();
                    }}
                  >
                    zoom_out_map
                  </span>
                </div>
                {/* <div className="col-md-2">
                  <span
                    className="material-icons"
                    // onClick={() => {
                    //   this.onClick_zoomout();
                    // }}
                  >
                    share
                  </span>
                </div> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ) : (
      <div
        id="mobile-gallery-slide-show-main-div"
        className={`${this.state.is_landscape ? "landscape" : ""}`}
      >
        <div className="margin-top-mobileview"></div>
        <div
          className={`${
            is_image_portrait ? "image_protrait" : "image_landscape"
          }`}
        >
          <PhotoArtTopImg
            src={`${BASE_URL}/cms-system/uploads/${items[home_index].url}`}
            height={items[home_index].height}
            width={items[home_index].width}
            nav_img_onclick={onClickImage}
            onChanceFullScreenHandler={this.onChangeFullScreenState}
            enlarge_first={this.state.large}
            exit_slide_show={this.exit_slide_show}
            enter_slide_show={this.enter_slide_show}
            index={home_index}
            items_a={items[home_index]}
            ref={isGallery ? forwardedRef : this.child}
            is_mobile={true}
            set_fullscreen_message={this.props.set_fullscreen_message}
          />
          <div className="gallery-slide-show-title">
            {items[home_index].title}
          </div>

          <div className="gallery-slide-show-body">
            {items[home_index].body}
          </div>
          <div className="slide-show-tags">
            <>
              {items[home_index].tags
                .split(",")
                .map((tag) => tag.trim())
                .filter((tag) => tag !== "")
                .map((tag, idx) => {
                  return (
                    <Badge key={idx} color="secondary">
                      {tag}
                    </Badge>
                  );
                })}
              <Badge color="secondary" className="invisible">
                {"l"}
              </Badge>
            </>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />

        <div className="gallery-slide-show-controller">
          <div>
            <div className="inline">
              <span className="material-icons" onClick={() => onClickImage(0)}>
                arrow_back_ios
              </span>
            </div>
            <div className="inline">
              <span className="material-icons" onClick={() => onClickImage(1)}>
                arrow_forward_ios
              </span>
            </div>
            <div className="inline" onClick={this.props.go_back_to_gallery}>
              <Link to={`/photography/gallery?id=${cat_id}`}>
                <span className="material-icons">reply</span>
              </Link>
            </div>
            <div className="inline">
              <span
                className="material-icons"
                onClick={() => {
                  this.props.set_fullscreen_message(true);
                  setTimeout(() => {
                    // alert(
                    //   "this.props.fullscreen_message: " +
                    //     this.props.fullscreen_message
                    // );
                    this.onClick_zoomout();
                  }, 10);

                  // this.onClick_zoomout();
                }}
              >
                zoom_out_map
              </span>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div
        className={`new-slide-show-div ${
          isGallery ? "not-diplay-slideshow" : ""
        }`}
      >
        {slide_show_html}
        {/* {!isTablet && (
          <BrowserView>
            <Container className="home-container normal">
              <Row className="gallery-row display-row slide-show">
                <Col
                  xl="8"
                  className="container img-container desktop-slide-show-container"
                >
                  <PhotoArtTopImg
                    src={`${BASE_URL}/cms-system/uploads/${items[home_index].url}`}
                    nav_img_onclick={onClickImage}
                    onChanceFullScreenHandler={this.onChangeFullScreenState}
                    enlarge_first={this.state.large}
                    exit_slide_show={this.exit_slide_show}
                    enter_slide_show={this.enter_slide_show}
                    index={home_index}
                    items_a={items[home_index]}
                    ref={isGallery ? forwardedRef : this.child}
                  />
                </Col>
                <Col xl="1"></Col>
                <Col xl="3" className="container desc ">
                  <div className="left-desc-div vertical-center">
                    <div className="gallery-slide-show-title">
                      {items[home_index].title}
                    </div>
                    <div className="gallery-slide-show-content-and-location-div">
                      <div className="gallery-slide-show-content">
                        <pre>{items[home_index].body}</pre>
                      </div>
                      <div className="gallery-slide-show-location">
                        {items[home_index].location_name}
                      </div>
                    </div>
                    <div>
                      <>
                        {items[home_index].tags
                          .split(",")
                          .map((tag) => tag.trim())
                          .filter((tag) => tag !== "")
                          .map((tag) => {
                            return <Badge color="secondary">{tag}</Badge>;
                          })}
                        <Badge color="secondary" className="invisible">
                          {"l"}
                        </Badge>
                      </>
                    </div>
                    <div className="row gallery-slide-show-div">
                      <div className="col-md-3">
                        <span
                          className="material-icons"
                          onClick={() => onClickImage(0)}
                        >
                          arrow_back_ios
                        </span>
                      </div>
                      <div className="col-md-3">
                        <span
                          className="material-icons"
                          onClick={() => onClickImage(1)}
                        >
                          arrow_forward_ios
                        </span>
                      </div>
                      <div
                        className="col-md-3"
                        onClick={this.props.go_back_to_gallery}
                      >
                        <Link to={`/photo-art/gallery?id=${cat_id}`}>
                          <span className="material-icons">reply</span>
                        </Link>
                      </div>
                      <div className="col-md-3">
                        <span
                          className="material-icons"
                          onClick={() => {
                            this.onClick_zoomout();
                          }}
                        >
                          zoom_out_map
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </BrowserView>
        )} */}
        {/* <MobileView>
          <div
            id="mobile-gallery-slide-show-main-div"
            className={`${this.state.is_landscape ? "landscape" : ""}`}
          >
            <div className="margin-top-mobileview"></div>
            <div>
              <PhotoArtTopImg
                src={`${BASE_URL}/cms-system/uploads/${items[home_index].url}`}
                height={items[home_index].height}
                width={items[home_index].width}
                nav_img_onclick={onClickImage}
                onChanceFullScreenHandler={this.onChangeFullScreenState}
                enlarge_first={this.state.large}
                exit_slide_show={this.exit_slide_show}
                enter_slide_show={this.enter_slide_show}
                index={home_index}
                items_a={items[home_index]}
                ref={isGallery ? forwardedRef : this.child}
                is_mobile={true}
              />
              <div className="gallery-slide-show-title">
                {items[home_index].title}
              </div>

              <div className="gallery-slide-show-body">
                {items[home_index].body}
              </div>
              <div className="slide-show-tags">
                <>
                  {items[home_index].tags
                    .split(",")
                    .map((tag) => tag.trim())
                    .filter((tag) => tag !== "")
                    .map((tag) => {
                      return <Badge color="secondary">{tag}</Badge>;
                    })}
                  <Badge color="secondary" className="invisible">
                    {"l"}
                  </Badge>
                </>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />

            <div className="gallery-slide-show-controller">
              <div>
                <div className="inline">
                  <span
                    className="material-icons"
                    onClick={() => onClickImage(0)}
                  >
                    arrow_back_ios
                  </span>
                </div>
                <div className="inline">
                  <span
                    className="material-icons"
                    onClick={() => onClickImage(1)}
                  >
                    arrow_forward_ios
                  </span>
                </div>
                <div className="inline" onClick={this.props.go_back_to_gallery}>
                  <Link to={`/photo-art/gallery?id=${cat_id}`}>
                    <span className="material-icons">reply</span>
                  </Link>
                </div>
                <div className="inline">
                  <span
                    className="material-icons"
                    onClick={() => {
                      this.onClick_zoomout();
                    }}
                  >
                    zoom_out_map
                  </span>
                </div>
              </div>
            </div>
          </div>
        </MobileView> */}
      </div>
    );
  }
}

export default withForwardedRef(NewSlideShow);
